import React, { useState, useEffect } from 'react';
import axios from 'axios';
import DataTable from 'react-data-table-component';
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css'; 
import { Button, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Spinner } from 'reactstrap';
import MyReportsEditModal from './MyReportsEditModal';
import {saveAs} from 'file-saver';

const Reports = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);

    const [isOpenEdit, setIsOpenEdit] = useState(false);
    const [isOpenDelete, setIsOpenDelete] = useState(false);
    const [certificateId, setCertificateId] = useState(null);
    const [certiData, setCertiData] = useState({});
    const [query, setQuery] = useState("");

    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [page, setPage] = useState(1);

    const toggleEditModal = () => setIsOpenEdit(!isOpenEdit);

    const toggleDeleteModal = () => setIsOpenDelete(!isOpenDelete);

    useEffect(() => {
        if(!isOpenEdit && !isOpenDelete){
            fetchData(1, perPage);
        }
    }, [perPage, isOpenEdit, isOpenDelete, fromDate, toDate]);

    const fetchData = async (page, perPage) => {
        setLoading(true);
        try {
            // Fetch agentId from localStorage
            const agentId = localStorage.getItem('agentId');
            console.log(agentId);

            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/auth/list-by-params/issuedcertificatesByAgent/${agentId}`, {
                skip: (page - 1) * perPage,
                per_page: perPage,
                sorton: 'issueDate',
                sortdir: 'asc',
                match: query,
                startDate: fromDate,
                endDate: toDate,
            });
            console.log("2");
            console.log(response.data);

            const certificates = response.data.map(cert => ({
                certificateName: cert.certificate?.certificateName || 'No Certificate Name',
                issueDate: cert.issueDate,
                expireDate: cert.expireDate,
                companyName: cert.company?.companyName || 'No Company',
                certificatePDFUrl: cert.certificatePDFUrl ? encodeURI(cert.certificatePDFUrl) : null,
                remarks: cert.remarks,
                certificateId: cert._id || 'No Certificate Id',
                qrCodeImage: cert.qrCodeImage ? cert.qrCodeImage : null,
                // companyId: cert.company._id || 'No Company Id',
            }));
            console.log(certificates);

            setData(certificates);
            setTotalRows(response.total);
            // toast.success("Issued certificates fetched successfully");
        } catch (error) {
            console.error("Error fetching data: ", error);
            toast.error("Unable to fetched issued certificate");
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData(1, perPage);
    }, [query]);

    const handlePageChange = (page) => {
        setPage(page);
        fetchData(page, perPage);
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        setPerPage(newPerPage);
        fetchData(page, newPerPage);
    };

    const handleDelete = async () => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/auth/delete/companyCertificate/${certificateId}`);
            console.log(response);
            toast.success("Certificate Releted successfully");
            toggleDeleteModal();
            fetchData(1, perPage);
        } catch (error) {
            toast.error("Unable to remove certificate");
            console.error("Error removing certificate: ", error);
        }
    };

    const sendReminder = async (certificateId) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/auth/sendReminders/${certificateId}`);
            console.log(response);
            toast.success("Reminder sent successfully");
        } catch (error) {
            toast.error("Unable to send reminder");
            console.error("Error sending reminder: ", error);
        }
    };

    const columns = [
        {
            name: 'Certificate Name',
            cell: row => row.certificateName,
            // sortable: true,
            maxWidth:"200px"
        },
        {
            name: 'Issue Date',
            selector: row => new Date(row.issueDate).toLocaleDateString(),
            // sortable: true,
        },
        {
            name: 'Expire Date',
            selector: row => row.expireDate===null||row.expireDate==="1970-01-01T00:00:00.000Z" ?'Lifetime': new Date(row.expireDate).toLocaleDateString()  ,
            // sortable: true,
            // sorton:"expireDate"
        },
        {
            name: 'Client',
            cell: row => row.companyName,
            // sortable: true,
            maxWidth:"200px"
        },
        {
            name: 'Remarks',
            cell: row => row.remarks ? row.remarks : 'No Remarks',
            minWidth:"130px"
            // sortable: true,
        },
        {
            name: 'QR Code',
            // selector: row=> row.qrCodeImage ? row.qrCodeImage : 'No QR Code',
            cell: row => (
                <>
                    <img src={row.qrCodeImage} alt="PDF not uploaded" style={{ width: '100px', height: '100px' }} />
                </>
            ),
        },
        {
            name: 'Actions',
            cell: row => (
                <>
                <Button
                    color="warning"
                    className="view-btn"
                    style={{ margin: '0 4px', fontSize: '0.875rem', lineHeight: '1.25rem' }}
                    onClick={() => {
                        sendReminder(row.certificateId)
                    }}
                    disabled={row.expireDate===null||row.expireDate==="1970-01-01T00:00:00.000Z" ?true:false}
                >
                    Send Reminder
                </Button>

                <Button 
                    color="primary"
                    className="view-btn"
                    style={{ margin: '0 4px', fontSize: '0.875rem', lineHeight: '1.25rem' }}
                    onClick={() => {
                        setIsOpenEdit(!isOpenEdit);
                        setCertificateId(row.certificateId);
                        setCertiData(row);
                        console.log(row);
                    }}
                >
                        Update
                </Button>
                <Button 
                    color="primary"
                    className="view-btn" 
                    style={{ margin: '0 4px', fontSize: '0.875rem', lineHeight: '1.25rem' }}
                    onClick={() => row.certificatePDFUrl && window.open(row.certificatePDFUrl, '_blank')}
                    disabled={!row.certificatePDFUrl}
                >
                    View
                </Button>
                <Button 
                    color="danger"
                    className="view-btn" 
                    style={{ margin: '0 4px', fontSize: '0.875rem', lineHeight: '1.25rem' }}
                    onClick={()=>{
                        setIsOpenDelete(!isOpenDelete);
                        setCertificateId(row.certificateId);
                    }}
                >
                    Delete
                </Button>
                </>
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            // button: true,
            width: '400px',
        },
    ];


    const handleDownloadExcel = async () => {
        try {
        //   let skip = (pageNo - 1) * perPage;
        //   if (skip < 0) {
        //     skip = 0;
        //   }
      const agentId = localStorage.getItem('agentId');
          const response = await axios.post(
            `${process.env.REACT_APP_BASE_URL}/auth/sheet/issuedCertificatesByAgent/${agentId}`,
            {
                skip: (page - 1) * perPage,
                per_page: perPage,
                sorton: 'issueDate',
                sortdir: 'asc',
                match: query,
                startDate: fromDate,
                endDate: toDate,
            },
            {
              responseType: 'blob', // Important to handle binary data
            }
          );
      
          console.log("excel response", response);
      
          // Create a new Blob object using the response data of the file
          const blob = new Blob([response], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          });
      
          // Save the file using FileSaver
          saveAs(blob, 'report.xlsx');
        } catch (error) {
          console.error("Error downloading the file:", error);
            toast.error("Error downloading the file");
        }
      };
      

    document.title = " Reports | EHS Certificate ";

    return (
        <div className="container mt-4" style={{ marginTop: "4rem", paddingTop: "5.3rem" }}>
            <div className="card">
                <div className="card-header d-flex justify-content-between align-items-center">
                    <h4>Reports</h4>
                    <div className='d-flex flex-row align-items-center'>
                        <div className='d-flex align-items-center' style={{minWidth : "200px", margin : "0 8px"}}>
                            <Label for="fromDate">From</Label>
                            <Input
                                type="date"
                                name="fromDate"
                                id="fromDate"
                                value={fromDate}
                                onChange={(e) => setFromDate(e.target.value)}
                                // required
                            />
                        </div>
                        <div className='d-flex align-items-center' style={{minWidth : "50px", margin : "0 8px"}}>
                            <Label for="toDate">To</Label>
                            <Input
                                type="date"
                                name="toDate"
                                id="toDate"
                                value={toDate}
                                onChange={(e)=> setToDate(e.target.value)}
                                // required
                            />
                        </div>
                        {/* search */}
                        <div
                            className="search-box ms-2 d-flex align-items-center"
                        >
                            <input
                                className="form-control search"
                                placeholder="Search..."
                                onChange={(e) => setQuery(e.target.value)}
                            />
                            <i className="ri-search-line search-icon "></i>
                            {/* <Button
                                color="success"
                                className="view-btn"
                                style={{ margin: '0 8px', fontSize: '0.875rem', width: '150px' }}
                                onClick={handleDownloadExcel}
                            >
                                <i className="ri-download-2-fill align-bottom me-1"></i>{" "}
                                Get Excel
                            </Button> */}
                        </div>
                        <Button
                            color="success"
                            className="view-btn"
                            style={{ margin: '0 8px' }}
                            onClick={handleDownloadExcel}
                        >
                            <i className="ri-download-2-fill align-bottom me-1"></i>{" "}
                            Get Excel
                        </Button>
                    </div>
                </div>
                <div className="card-body">
                    <div className="table-responsive">
                        <DataTable
                            columns={columns}
                            data={data}
                            progressPending={loading}
                            progressComponent={<Spinner color="primary" />}
                            pagination
                            paginationServer
                            paginationTotalRows={totalRows}
                            paginationRowsPerPageOptions={[10, 50, 100, totalRows]}
                            onChangeRowsPerPage={handlePerRowsChange}
                            onChangePage={handlePageChange}
                        />
                    </div>
                </div>
                <MyReportsEditModal 
                    isOpen={isOpenEdit} 
                    toggleModal={toggleEditModal} 
                    certificateId={certificateId} 
                    certiData={certiData}
                    agentId={localStorage.getItem('agentId')}
                />

    <Modal
        isOpen={isOpenDelete}
        toggle={toggleDeleteModal}
        centered
      >
        <ModalHeader
          className="bg-light p-3"
          toggle={toggleDeleteModal}
        >
          <span style={{ marginRight: "210px" }}>Remove Report</span>
        </ModalHeader>
        <form>
          <ModalBody>
            <div className="mt-2 text-center">
              <lord-icon
                src="https://cdn.lordicon.com/gsqxdxog.json"
                trigger="loop"
                colors="primary:#f7b84b,secondary:#f06548"
                style={{ width: "100px", height: "100px" }}
              ></lord-icon>
              <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
                <h4>Are you sure ?</h4>
                <p className="text-muted mx-4 mb-0">
                  Are you Sure You want to Remove this Record ?
                </p>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <div className="hstack gap-2 justify-content-end">
              <button
                type="submit"
                className="btn btn-danger"
                id="add-btn"
                onClick={handleDelete}
              >
                Remove
              </button>
              <button
                type="button"
                className="btn btn-outline-danger"
                onClick={toggleDeleteModal}
              >
                Close
              </button>
            </div>
          </ModalFooter>
        </form>
      </Modal>
            </div>
            <ToastContainer />
        </div>
    );
};

export default Reports;
