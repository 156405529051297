import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button, Box, Grid, Typography, TextField } from '@mui/material';
import { toast, ToastContainer } from 'react-toastify';

const EditPrefix = ({ toggle, prefixData, onPrefixUpdated }) => {
    const [agentId, setAgentId] = useState('');
    const [prefix, setPrefix] = useState('');
    const [errors, setErrors] = useState({});

    useEffect(() => {
        const agentId = localStorage.getItem('agentId');
        if (agentId) {
            setAgentId(agentId);
        }
        if (prefixData) {
            setPrefix(prefixData.prefix);
        }
    }, [prefixData]);

    const validate = () => {
        const newErrors = {};
        if (!prefix) {
            newErrors.prefix = 'Prefix is required.';
        }
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handlePrefixChange = (event) => {
        setPrefix(event.target.value);
    };

    const handleSubmit = async () => {
        if (!validate()) {
            return;
        }
        try {
            await axios.put(`${process.env.REACT_APP_BASE_URL}/auth/update/prefix/${prefixData._id}`, {
                agentId,
                prefix
            });
            toggle();
            toast.success("Prefix updated successfully");
            onPrefixUpdated(); // Call the callback to refresh the prefixes
        } catch (error) {
            console.error('Error updating prefix:', error);
            toast.error("Error updating prefix");
        }
    };

    return (
        <Box display="flex" flexDirection="column" alignItems="center" mt={4} height={'28rem'}>
                        <Typography variant="h4" component="div" style={{ textAlign: 'center', fontWeight: 'bold' }}>
                Edit Prefix
            </Typography>
            <Grid container spacing={2} mt={3} style={{ maxWidth: '600px' }}>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label="Prefix"
                        variant="outlined"
                        value={prefix}
                        onChange={handlePrefixChange}
                    />
                    {errors.prefix && (
                        <Typography color="error" variant="body2">
                            {errors.prefix}
                        </Typography>
                    )}
                </Grid>
                <Grid item xs={12} style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={toggle}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSubmit}
                    >
                        Submit
                    </Button>
                </Grid>
            </Grid>
            <ToastContainer />
        </Box>
    );
};

export default EditPrefix;

