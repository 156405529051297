import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Select from 'react-select';
import { Label } from 'reactstrap';

const SearchableDropdown = ({ selectedCertificates, setSelectedCertificates }) => {
    const [options, setOptions] = useState([]);

    useEffect(() => {
        const fetchCertificates = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/list/certificateMaster`);
                const fetchedCertificates = response.map(cert => ({
                    value: cert._id,
                    label: cert.certificateName
                }));
                setOptions(fetchedCertificates);
            } catch (error) {
                console.error('Error fetching certificates:', error);
            }
        };

        fetchCertificates();
    }, []);

    const handleChange = (selectedOptions) => {
        setSelectedCertificates(selectedOptions ? selectedOptions.map(option => option.value) : []);
    };

    return (
        <div>
            {/* <div className="form-label">Certificates:</div> */}
        <Label htmlFor="mobileNumber" className="form-label">
            Certificates: <span className="text-danger">*</span>
        </Label>
            <Select
                isMulti
                name="certificates"
                options={options}
                className="basic-multi-select"
                classNamePrefix="select"
                value={options.filter(option => selectedCertificates.includes(option.value))}
                onChange={handleChange}
            />
        </div>
    );
};

export default SearchableDropdown;
