import React, { useEffect, useState } from 'react';
import { Container } from 'reactstrap';
import { Box } from '@mui/material';
import axios from 'axios';
import BannerImg from '../../assets/images/certificate/banner.jpg';

const AgentDashboard = () => {
    const [agentName, setAgentName] = useState('Agent Name');

    useEffect(() => {
        const fetchAgentName = async () => {
            const agentId = localStorage.getItem('agentId');
            try {
                const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/auth/get/agentById/${agentId}`, {match:""});
                const agentData = response.agent.name;
                setAgentName(agentData);
            } catch (error) {
                console.error('Error fetching agent details:', error);
            }
        };

        fetchAgentName();
    }, []);

    document.title = "Dashboard | EHS Certificate";

    return (
        <Container className="page-content">
            <Box display="flex" flexDirection="column" alignItems="center" mt={5}>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <h2>Welcome, {agentName}</h2>
                    <img src={BannerImg} alt="banner" style={{ width: '80%', margin: '2rem' }} />
                </div>
            </Box>
        </Container>
    );
};

export default AgentDashboard;
