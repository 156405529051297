import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Select from 'react-select';
import 'bootstrap/dist/css/bootstrap.min.css';

const SeearchableDropdown = ({ selectedCertificates, setSelectedCertificates, setSelectedCertificate }) => {
    const [options, setOptions] = useState([]);
    const [certificateDetails, setCertificateDetails] = useState([]);
    const [loading, setLoading] = useState(false);
    const [providingCertificates, setProvidingCertificates] = useState([]);

    useEffect(() => {
        const fetchAgentData = async () => {
            const agentId = localStorage.getItem('agentId');
            if (agentId) {
                try {
                    const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/auth/get/agentById/${agentId}`);
                    const agentData = response.agent; // Access response.data to get agent details
                    setProvidingCertificates(agentData.providingCertificates || []);
                } catch (error) {
                    console.error('Error fetching agent details:', error);
                }
            }
        };

        const fetchCertificates = async () => {
            setLoading(true);
            try {
                const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/list/certificateMaster`);
                setCertificateDetails(response); // Assuming response.data is an array of certificate objects
            } catch (error) {
                console.error('Error fetching certificates:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchAgentData();
        fetchCertificates();
    }, []);

    useEffect(() => {
        if (providingCertificates.length > 0 && certificateDetails.length > 0) {
            const matched = providingCertificates.map(certId => {
                const certificate = certificateDetails.find(cert => cert._id === certId);
                return {
                    value: certId,
                    label: certificate ? certificate.certificateName : certId // Fallback to certId if certificate not found
                };
            });
            setOptions(matched);
        }
    }, [providingCertificates, certificateDetails]);

    const handleChange = (selectedOptions) => {
        setSelectedCertificates(selectedOptions ? selectedOptions.map(option => option.value) : []);
        setSelectedCertificate(selectedOptions && selectedOptions.length > 0 ? selectedOptions[0].value : null);
    };

    return (
        <div className="my-3">
            <h3>Select Certificates:</h3>
            <Select
                isMulti
                name="certificates"
                options={options}
                className="basic-multi-select"
                classNamePrefix="select"
                value={options.filter(option => selectedCertificates.includes(option.value))}
                onChange={handleChange}
                menuPortalTarget={document.body}
                styles={{
                    menuPortal: base => ({ ...base, zIndex: 9999 }) // Ensure dropdown appears on top
                }}
            />
        </div>
    );
};

export default SeearchableDropdown;
