import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
    Button,
    Box,
    Grid,
    Typography,
    TextField,
    MenuItem,
    Select,
    FormControl,
    InputLabel,
    Checkbox,
    ListItemText,
} from '@mui/material';
import { toast, ToastContainer } from 'react-toastify';

const EditFields = ({ toggle, prefixData, onPrefixUpdated }) => {
    const [agentId, setAgentId] = useState('');
    const [agentName, setAgentName] = useState('Agent Name');
    const [providingCertificates, setProvidingCertificates] = useState([]);
    const [allCertificates, setAllCertificates] = useState([]);
    const [matchedCertificates, setMatchedCertificates] = useState([]);
    const [selectedCertificates, setSelectedCertificates] = useState(prefixData.certificates.map(c => c._id));
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const [fieldType, setFieldType] = useState(prefixData.fieldType);
    const [fieldName, setFieldName] = useState(prefixData.fieldName);

    useEffect(() => {
        fetchAgentData();
        fetchCertificates();
    }, []);

    useEffect(() => {
        if (providingCertificates.length > 0 && allCertificates.length > 0) {
            const matched = allCertificates.filter(cert => providingCertificates.includes(cert._id));
            setMatchedCertificates(matched);
            setSelectedCertificates(prefixData.certificates.map(c => c._id)); // Assuming prefixData contains certificates
        }
    }, [providingCertificates, allCertificates, prefixData]);

    const fetchAgentData = async () => {
        const agentId = localStorage.getItem('agentId');
        if (agentId) {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/auth/get/agentById/${agentId}`);
                const agentData = response.agent; // Assuming response contains data property
                setAgentId(agentData._id);
                setAgentName(agentData.name || 'Agent Name');
                setProvidingCertificates(agentData.providingCertificates || []);
            } catch (error) {
                console.error('Error fetching agent details:', error);
            }
        }
    };

    const fetchCertificates = async () => {
        setLoading(true);
        try {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/list/certificateMaster`);
            setAllCertificates(response); // Assuming response contains data property
        } catch (error) {
            toast.error("Unable to fetch certificates");
            console.error('Error fetching certificates:', error);
        } finally {
            setLoading(false);
        }
    };

    const validate = () => {
        const newErrors = {};
        // Validation logic for field name, field type, etc.
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleCertificatesChange = (event) => {
        setSelectedCertificates(event.target.value);
    };

    const handleFieldTypeChange = (event) => {
        setFieldType(event.target.value);
    };

    const handleFieldNameChange = (event) => {
        setFieldName(event.target.value);
    };

    const handleSubmit = async () => {
        if (!validate()) {
            return;
        }
        try {
            if(fieldName == ""){
                await axios.put(`${process.env.REACT_APP_BASE_URL}/auth/update/field/${prefixData._id}`, {
                    agentId,
                    fieldName: prefixData.fieldName,
                    fieldType: (fieldType || prefixData.fieldType).toLowerCase(),
                    certificate: selectedCertificates.length > 0 ? selectedCertificates : prefixData.certificates.map(c => c._id)
                });
                toggle();
                toast.success("Field updated successfully");
                onPrefixUpdated();
            }else{
                await axios.put(`${process.env.REACT_APP_BASE_URL}/auth/update/field/${prefixData._id}`, {
                    agentId,
                    fieldName: fieldName,
                    fieldType: (fieldType || prefixData.fieldType).toLowerCase(),
                    certificate: selectedCertificates.length > 0 ? selectedCertificates : prefixData.certificates.map(c => c._id)
                });
                toggle();
                toast.success("Field updated successfully");
                onPrefixUpdated();
            }
        } catch (error) {
            console.error('Error updating field:', error);
            toast.error("Error updating field");
        }
    };

    return (
        <Box display="flex" flexDirection="column" alignItems="center" mt={4}>
            <Typography variant="h4" component="div" style={{ textAlign: 'center', fontWeight: 'bold', marginBottom: '1rem' }}>
                Edit Fields
            </Typography>
            <Grid container spacing={2} style={{ maxWidth: '600px' }}>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label="Field Name"
                        variant="outlined"
                        value={fieldName}
                        onChange={handleFieldNameChange}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormControl variant="outlined" fullWidth>
                        <InputLabel>Field Type</InputLabel>
                        <Select
                            value={fieldType}
                            onChange={handleFieldTypeChange}
                            label="Field Type"
                        >
                            <MenuItem value="image">Image</MenuItem>
                            <MenuItem value="date">Date</MenuItem>
                            <MenuItem value="text">Text</MenuItem>
                            <MenuItem value="number">Number</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl variant="outlined" fullWidth>
                        <InputLabel>Certificates</InputLabel>
                        <Select
                            multiple
                            value={selectedCertificates}
                            onChange={handleCertificatesChange}
                            renderValue={(selected) => selected.map(id => matchedCertificates.find(cert => cert._id === id)?.certificateName).join(', ')}
                        >
                            {matchedCertificates.map((certificate) => (
                                <MenuItem key={certificate._id} value={certificate._id}>
                                    <Checkbox checked={selectedCertificates.indexOf(certificate._id) > -1} />
                                    <ListItemText primary={certificate.certificateName} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    {errors.certificates && (
                        <Typography color="error" variant="body2">
                            {errors.certificates}
                        </Typography>
                    )}
                </Grid>
                <Grid item xs={12} style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={toggle}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSubmit}
                    >
                        Submit
                    </Button>
                </Grid>
            </Grid>
            <ToastContainer />
        </Box>
    );
};

export default EditFields;
