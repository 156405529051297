import React, { useEffect, useState } from "react";
import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button,
    Form,
    FormGroup,
    Label,
    Input,
} from "reactstrap";
import axios from "axios";
import { useDropzone } from "react-dropzone";
import { Select } from "antd";
import { toast } from "react-toastify";
import { Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { set } from "date-fns";

const initialState = {
    companyName: "",
    email: "",
    password: "",
    mobileNumber: "",
    companyCategory: "",
    companyProof: null,
    country: "",
    state: "",
    city: "",
    address: "",
};

const AddClient = ({ isOpen, toggleModal, currentCompanies }) => {
    const [newCompany, setNewCompany] = useState({
        companyName: "",
        password: "",
        mobileNumber: "",
        email: "",
        companyCategory: "",
        isActive: true,
        address: "",
        isVerified: false,
    });

    const [newCompanyId, setNewCompanyId] = useState("");
    const [createNewCompany, setCreateNewCompany] = useState(false);
    const [isLifetime, setisLifetime] = useState(false);
    

    const [allCompanies, setallCompanies] = useState([]);
    const [selectedCompany, setSelectedCompany] = useState(null);
    const [file, setFile] = useState(null);
    const [loading, setLoading] = useState(false);
    const [options, setOptions] = useState([]);

    const getAllCompanies = async () => {
        try {
            const agentId = localStorage.getItem("agentId");
            const response = await axios.get(
                `${process.env.REACT_APP_BASE_URL}/auth/list/companiesNotInAgent/${agentId}`
            );
            console.log("Response:", response);
            const companies = response.companiesNotInAgent.map((company) => ({
                value: company._id,
                label: company.companyName + " - " + company.mobileNumber,
                companyData: company,
            }));
            setallCompanies(companies);
        } catch (error) {
            console.error("Error fetching companies:", error);
            toast.error("Companies failed to fetch !");
        }
    };

    useEffect(() => {
        getAllCompanies();
    }, []);

    const [formErrors, setFormErrors] = useState({});
    const [isSubmit, setIsSubmit] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        // console.log("name:", name, "value:", value)
        setValues({ ...values, [name]: value });
        if (name === "country") {
            setSelectedCountryId(value);
            axios
                .post(
                    `${process.env.REACT_APP_BASE_URL}/list/statesByCountry`,
                    { countryID: value }
                )
                .then((response) => {
                    setStates(response);
                    // console.log("states:", response);
                })
                .catch((error) =>
                    console.error("Error fetching states:", error)
                );
        } else if (name === "state") {
            setSelectedStateId(value);
            axios
                .post(`${process.env.REACT_APP_BASE_URL}/list/cityByState`, {
                    StateID: value,
                })
                .then((response) => {
                    setCities(response);
                    console.log("cities:", response);
                })
                .catch((error) =>
                    console.error("Error fetching cities:", error)
                );
        } else if (name === "city") {
            setSelectedCityId(value);
        } else if (name === "companyCategory") {
            console.log(name, value);
            setNewCompany({ ...newCompany, [name]: value });
        }
    };

    const validateRegister = (values) => {
        const errors = {};
        setNoSubmit(false); // Reset noSubmit to false

        if (!values.companyName) {
            setNoSubmit(true);
            errors.companyName = "Company Name is required!";
        }
        if (!values.mobileNumber) {
            setNoSubmit(true);
            errors.mobileNumber = "Mobile Number is required!";
        } else if (!/^\d{10}$/.test(values.mobileNumber)) {
            setNoSubmit(true);
            errors.mobileNumber = "Invalid Mobile Number!";
        }
        if (!values.email) {
            setNoSubmit(true);
            errors.email = "Email is required!";
        } else if (!/\S+@\S+\.\S+/.test(values.email)) {
            setNoSubmit(true);
            errors.email = "Invalid Email!";
        }
        if (!values.companyCategory) {
            setNoSubmit(true);
            errors.companyCategory = "Company Category is required!";
        }
        if (!values.country) {
            setNoSubmit(true);
            errors.country = "Country is required!";
        }
        if (!values.state) {
            setNoSubmit(true);
            errors.state = "State is required!";
        }
        if (!values.city) {
            setNoSubmit(true);
            errors.city = "City is required!";
        }
        if (!values.address) {
            setNoSubmit(true);
            errors.address = "Address is required!";
        }
        return errors;
    };

    const register = async () => {
        setIsSubmit(true); // Assuming this controls form submission state
        const formErrors = validateRegister(values); // Validate the form
        setFormErrors(formErrors); // Set form errors

        if (Object.keys(formErrors).length > 0) {
            setLoading(false);
            setNoSubmit(true); // Prevent submission if there are validation errors
            return;
        }

        try {
            // Step 1: Create address data
            const addressObject = {
                CityID: selectedCityId,
                StateID: selectedStateId,
                CountryID: selectedCountryId,
                Address: values.address,
            };

            const addressResponse = await axios.post(
                `${process.env.REACT_APP_BASE_URL}/create/address`,
                addressObject
            );
            console.log("Response from createAddress API:", addressResponse.data);

            // Step 2: Prepare formData for company registration
            const formData = new FormData();
            formData.append("mobileNumber", values.mobileNumber);
            formData.append("email", values.email);
            formData.append("password", "12345678");
            formData.append("companyName", values.companyName);
            formData.append("companyCategory", values.companyCategory);
            formData.append("isActive", true);
            formData.append("isVerified", true);
            formData.append("address", addressResponse.data._id); // Assuming addressData has _id

            const companyResponse = await axios.post(
                `${process.env.REACT_APP_BASE_URL}/create/company`,
                formData
            );
            console.log("1", companyResponse);
            if (companyResponse) {
                const agentId = localStorage.getItem("agentId");
                const existingCompaniesOfAgents = currentCompanies.map(
                    (company) => company._id
                );
                console.log('2', companyResponse._id);
                const buffer = [
                    ...existingCompaniesOfAgents,
                    companyResponse.company._id,
                ];
                console.log('3', buffer);
                setTimeout(async() => {
                    await axios.put(
                        `${process.env.REACT_APP_BASE_URL}/auth/update/agent/${agentId}`,
                        {
                            companies: buffer,
                        }
                    );
                    console.log('4');
                }, 500);
                

                toast.success("Registration successful!");
                toggleModal(); // Close the modal after successful issuance
                setNewCompany({
                    companyName: "",
                    password: "",
                    mobileNumber: "",
                    email: "",
                    companyCategory: "",
                    isActive: true,
                    address: "",
                    isVerified: true,
                });
                setValues(initialState);
                setSelectedCompany(null);
                setNewCompany("");
            } else {
                toast.error("Registration failed!");
            }
        } catch (error) {
            console.error("Error during registration:", error);
            toast.error("Registration failed! Please provide proper credentials.");
        } finally {
            handleCancel();
            setLoading(false);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        if (createNewCompany) {
            await register();
        } else if (selectedCompany) {
            try {
                const agentId = localStorage.getItem("agentId");
                const existingCompaniesOfAgents = currentCompanies.map(
                    (company) => company._id
                );
                const buffer = [
                    ...existingCompaniesOfAgents,
                    selectedCompany.allCompanies._id,
                ];

                await axios.put(
                    `${process.env.REACT_APP_BASE_URL}/auth/update/agent/${agentId}`,
                    {
                        companies: buffer,
                    }
                );

                toast.success("Client added successfully!");
                toggleModal(); // Close the modal after successful issuance
                setNewCompany({
                    companyName: "",
                    password: "",
                    mobileNumber: "",
                    email: "",
                    companyCategory: "",
                    isActive: true,
                    address: "",
                    isVerified: true,
                });
                setSelectedCompany(null);
                setNewCompany("");
            } catch (error) {
                console.error("Error issuing certificate:", error);
                toast.error("Error Issuing certificate");
            } finally {
                handleCancel();
                setLoading(false);
            }
        } else {
            toast.error("Please select or create a client");
            setLoading(false);
        }
    };

    const handleCheckBox = (e) => {
        console.log(e.target.value);
        setCreateNewCompany(!createNewCompany);
        if (createNewCompany) {
            setNewCompany("");
        } else {
            setSelectedCompany(null);
        }
    };

    const handleCancel = () => {
        // setNewCompany({
        //     companyName: "",
        //     password: "",
        //     mobileNumber: "",
        //     email: "",
        //     companyCategory: "",
        //     isActive: true,
        //     address: "",
        //     isVerified: false,
        // });
        setNewCompany({
            companyName: "",
            password: "",
            mobileNumber: "",
            email: "",
            companyCategory: "",
            isActive: true,
            address: "",
            isVerified: true,
        });
        setValues(initialState);
        // setSelectedCompany(null);
        // setNewCompany("");
        setSelectedCompany(null);
        setCreateNewCompany(false);
        setLoading(false)
        toggleModal(); // Close the modal
    };

    useEffect(() => {
        setSelectedCompany(null);
    }, isOpen);

    const [values, setValues] = useState(initialState);

    const validate = (values) => {
        const errors = {};
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!values.email) {
            errors.email = "Email is required!";
        } else if (!regex.test(values.email)) {
            errors.email = "Invalid Email address!";
        }
        if (!values.password) {
            errors.password = "Password is required!";
        }
        return errors;
    };

    const [noSubmit, setNoSubmit] = useState(false);

    // const validateRegister = (values) => {
    //     const errors = validate(values);
    //     if (!values.companyName) {
    //         setNoSubmit(true);
    //         errors.companyName = "Company Name is required!";
    //     }
    //     if (!values.mobileNumber) {
    //         setNoSubmit(true);
    //         errors.mobileNumber = "Mobile Number is required!";
    //     } else if (!/^\d{10}$/.test(values.mobileNumber)) {
    //         setNoSubmit(true);
    //         errors.mobileNumber = "Invalid Mobile Number!";
    //     }
    //     if (!values.companyCategory) {
    //         setNoSubmit(true);
    //         errors.companyCategory = "Company Category is required!";
    //     }
    //     if (!values.companyProof) {
    //         setNoSubmit(true);
    //         errors.companyProof = "A valid Company Proof is required!";
    //     }
    //     if (!values.country) {
    //         setNoSubmit(true);
    //         errors.country = "Country is required!";
    //     }
    //     if (!values.state) {
    //         setNoSubmit(true);
    //         errors.state = "State is required!";
    //     }
    //     if (!values.city) {
    //         setNoSubmit(true);
    //         errors.city = "City is required!";
    //     }
    //     if (!values.address) {
    //         setNoSubmit(true);
    //         errors.address = "Address is required!";
    //     }
    //     return errors;
    // };

    const validClassEmail =
        formErrors.email && isSubmit
            ? "form-control is-invalid"
            : "form-control";
    const validClassPassword =
        formErrors.password && isSubmit
            ? "form-control is-invalid"
            : "form-control pe-5";

    const [categories, setCategories] = useState([]);
    const [countries, setCountries] = useState([]);
    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);
    const [selectedCityId, setSelectedCityId] = useState("");
    const [selectedStateId, setSelectedStateId] = useState("");
    const [selectedCountryId, setSelectedCountryId] = useState("");

    const navigate = useNavigate();
    useEffect(() => {
        // Fetch categories
        axios
            .get(`${process.env.REACT_APP_BASE_URL}/list/categories`)
            .then((response) => {
                setCategories(response);
                // console.log("categories:", response);
            })
            .catch((error) => {
                console.error(
                    "There was an error fetching the categories!",
                    error
                );
            });

        // Fetch countries
        axios
            .get(`${process.env.REACT_APP_BASE_URL}/list/ActiveCountries`)
            .then((response) => {
                setCountries(response);
                // console.log("countries:", response);
            })
            .catch((error) =>
                console.error("Error fetching countries:", error)
            );
    }, []);

    useEffect(() => {}, [newCompanyId]);

    return (
        <Modal isOpen={isOpen} toggle={toggleModal}>
            <ModalHeader>Add Client</ModalHeader>
            <ModalBody>
                <Form onSubmit={handleSubmit}>
                    <FormGroup>
                        <Label for="company">Client</Label>
                        <Select
                            showSearch
                            disabled={createNewCompany}
                            value={selectedCompany}
                            style={{ width: "100%" }}
                            placeholder="Search and select company"
                            optionFilterProp="children"
                            onChange={(value, option) => {
                                setSelectedCompany(option);
                                // setNewCompanyId(option.value)
                                console.log(option);
                            }}
                            filterOption={(input, option) =>
                                option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {allCompanies.map((com) => (
                                <Select.Option
                                    key={com.value}
                                    value={com.value}
                                    allCompanies={com.companyData}
                                >
                                    {com.label}
                                </Select.Option>
                            ))}
                        </Select>
                    </FormGroup>
                    {selectedCompany && (
                        <div>
                            <p>
                                <strong>Name:</strong>{" "}
                                {selectedCompany.allCompanies.companyName}
                            </p>
                            <p>
                                <strong>Mobile:</strong>{" "}
                                {selectedCompany.allCompanies.mobileNumber}
                            </p>
                            <p>
                                <strong>Email:</strong>{" "}
                                {selectedCompany.allCompanies.email}
                            </p>
                        </div>
                    )}
                    <FormGroup check>
                        <Label check>
                            <Input
                                type="checkbox"
                                checked={createNewCompany}
                                onChange={handleCheckBox}
                            />{" "}
                            Can't find? Create new
                        </Label>
                    </FormGroup>
                    {createNewCompany && (
                        <>
                            {/* <> */}
                            <div className="mb-3">
                                <Label
                                    htmlFor="companyName"
                                    className="form-label"
                                >
                                    Client Name <span className="text-danger">*</span>
                                </Label>
                                <Input
                                    name="companyName"
                                    className={
                                        formErrors.companyName && isSubmit
                                            ? "form-control is-invalid"
                                            : "form-control"
                                    }
                                    placeholder="Enter company name"
                                    type="text"
                                    onChange={handleChange}
                                    value={values.companyName}
                                />
                                {isSubmit && (
                                    <p className="text-danger">
                                        {formErrors.companyName}
                                    </p>
                                )}
                            </div>
                            <Row>
                                <Col lg={6}>
                                    <div className="mb-3">
                                        <Label
                                            htmlFor="email"
                                            className="form-label"
                                        >
                                            Email <span className="text-danger">*</span>
                                        </Label>
                                        <Input
                                            name="email"
                                            className={validClassEmail}
                                            placeholder="Enter email"
                                            type="email"
                                            onChange={handleChange}
                                            value={values.email}
                                        />
                                        {isSubmit && (
                                            <p className="text-danger">
                                                {formErrors.email}
                                            </p>
                                        )}
                                    </div>
                                </Col>
                                <Col lg={6}>
                                    <div className="mb-3">
                                        <Label
                                            htmlFor="mobileNumber"
                                            className="form-label"
                                        >
                                            Mobile Number <span className="text-danger">*</span>
                                        </Label>
                                        <Input
                                            name="mobileNumber"
                                            className={
                                                formErrors.mobileNumber &&
                                                isSubmit
                                                    ? "form-control is-invalid"
                                                    : "form-control"
                                            }
                                            placeholder="Enter mobile number"
                                            type="text"
                                            onChange={handleChange}
                                            value={values.mobileNumber}
                                        />
                                        {isSubmit && (
                                            <p className="text-danger">
                                                {formErrors.mobileNumber}
                                            </p>
                                        )}
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={6}>
                                    <div className="mb-3">
                                        <Label
                                            htmlFor="companyCategory"
                                            className="form-label"
                                        >
                                            Client Category <span className="text-danger">*</span>
                                        </Label>
                                        <Input
                                            name="companyCategory"
                                            className={
                                                formErrors.companyCategory &&
                                                isSubmit
                                                    ? "form-control is-invalid"
                                                    : "form-control"
                                            }
                                            type="select"
                                            onChange={handleChange}
                                            value={values.companyCategory}
                                        >
                                            <option value="">
                                                Select Category
                                            </option>
                                            {categories &&
                                                categories.map(
                                                    (category, index) => (
                                                        <option
                                                            key={index}
                                                            value={category._id}
                                                        >
                                                            {
                                                                category.categoryName
                                                            }
                                                        </option>
                                                    )
                                                )}
                                        </Input>
                                        {isSubmit && (
                                            <p className="text-danger">
                                                {formErrors.companyCategory}
                                            </p>
                                        )}
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    <div className="mb-3">
                                        <Label
                                            htmlFor="country"
                                            className="form-label"
                                        >
                                            Country <span className="text-danger">*</span>
                                        </Label>
                                        <Input
                                            name="country"
                                            className={
                                                formErrors.country && isSubmit
                                                    ? "form-control is-invalid"
                                                    : "form-control"
                                            }
                                            type="select"
                                            onChange={handleChange}
                                            value={values.country}
                                        >
                                            <option value="" disabled>
                                                Select Country
                                            </option>
                                            {countries.map((country, index) => (
                                                <option
                                                    key={index}
                                                    value={country._id}
                                                >
                                                    {country.CountryName}
                                                </option>
                                            ))}
                                        </Input>
                                        {isSubmit && (
                                            <p className="text-danger">
                                                {formErrors.country}
                                            </p>
                                        )}
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className="mb-3">
                                        <Label
                                            htmlFor="state"
                                            className="form-label"
                                        >
                                            State <span className="text-danger">*</span>
                                        </Label>
                                        <Input
                                            name="state"
                                            className={
                                                formErrors.state && isSubmit
                                                    ? "form-control is-invalid"
                                                    : "form-control"
                                            }
                                            type="select"
                                            onChange={handleChange}
                                            value={values.state}
                                        >
                                            <option value="" disabled>
                                                Select State
                                            </option>
                                            {states &&
                                                states.map((state, index) => (
                                                    <option
                                                        key={index}
                                                        value={state._id}
                                                    >
                                                        {state.StateName}
                                                    </option>
                                                ))}
                                        </Input>
                                        {isSubmit && (
                                            <p className="text-danger">
                                                {formErrors.state}
                                            </p>
                                        )}
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    <div className="mb-3">
                                        <Label
                                            htmlFor="city"
                                            className="form-label"
                                        >
                                            City <span className="text-danger">*</span>
                                        </Label>
                                        <Input
                                            name="city"
                                            className={
                                                formErrors.city && isSubmit
                                                    ? "form-control is-invalid"
                                                    : "form-control"
                                            }
                                            type="select"
                                            onChange={handleChange}
                                            value={values.city}
                                        >
                                            <option value="" disabled>
                                                Select City <span className="text-danger">*</span>
                                            </option>
                                            {cities &&
                                                cities.map((city, index) => (
                                                    <option
                                                        key={index}
                                                        value={city._id}
                                                    >
                                                        {city.CityName}
                                                    </option>
                                                ))}
                                        </Input>
                                        {isSubmit && (
                                            <p className="text-danger">
                                                {formErrors.city}
                                            </p>
                                        )}
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className="mb-3">
                                        <Label
                                            htmlFor="address"
                                            className="form-label"
                                        >
                                            Address <span className="text-danger">*</span>
                                        </Label>
                                        <Input
                                            name="address"
                                            className={
                                                formErrors.address && isSubmit
                                                    ? "form-control is-invalid"
                                                    : "form-control"
                                            }
                                            placeholder="Enter address"
                                            type="text"
                                            onChange={handleChange}
                                            value={values.address}
                                        />
                                        {isSubmit && (
                                            <p className="text-danger">
                                                {formErrors.address}
                                            </p>
                                        )}
                                    </div>
                                </Col>
                            </Row>
                        </>
                    )}

                    <ModalFooter>
                        {/* <Button color="primary" type="submit" disabled={!newCompany.certificateId || loading}> */}
                        <Button color="primary" type="submit">
                            {loading ? "Adding..." : "Add Client"}
                        </Button>
                        <Button
                            color="secondary"
                            onClick={handleCancel}
                            disabled={loading}
                        >
                            Cancel
                        </Button>
                    </ModalFooter>
                </Form>
            </ModalBody>
        </Modal>
    );
};

export default AddClient;
