import React, { useEffect, useState } from "react";
import logoImg from './assets/images/certificate/logo.png'
import BannerIMG from './assets/images/certificate/banner.png'
import EHSABOUT from './assets/images/certificate/EHS-about.jpg'
import './assets/css/eduhive.css'
import FOOTERBANNER from './assets/images/certificate/footer-banner.png'
import './assets/vendors/bootstrap/css/bootstrap.min.css'
    import './assets/vendors/bootstrap-select/bootstrap-select.min.css'
    import './assets/vendors/animate/animate.min.css'
    import './assets/vendors/fontawesome/css/all.min.css'
    import './assets/vendors/jquery-ui/jquery-ui.css'
    import './assets/vendors/jarallax/jarallax.css'
    import './assets/vendors/jquery-magnific-popup/jquery.magnific-popup.css'
    import './assets/vendors/nouislider/nouislider.min.css'
    import './assets/vendors/nouislider/nouislider.pips.css'
    import './assets/vendors/tiny-slider/tiny-slider.css'
    import './assets/vendors/eduhive-icons/style.css'
    import './assets/vendors/owl-carousel/css/owl.carousel.min.css'
    import './assets/vendors/owl-carousel/css/owl.theme.default.min.css'
import { Input } from "reactstrap";
import axios from "axios";
import { Link } from "react-router-dom";
// import './assets/css/eduhive-rtl.css'
// import './assets/css/eduhive-dark.css'
// import './assets/css/eduhive-custom-rtl.css'
var random =1;
const tot =5;

const Landing = ()=>{
    const [options, setOptions] = useState([]);
    const [categoryId,setCategoryId] = useState('');
    const [certificates,setCertificates] = useState([]);

    const fetchCategories = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/list/categories`);
            console.log('Response:', response); // Log the response data to check its structure

            // Ensure the response data is an array and contains valid certificate objects
            if (Array.isArray(response)) {
                const categories = response.map(cert => ({
                    value: cert._id,
                    label: cert.categoryName
                }));
                setOptions(categories);
            } else {
                console.error('Invalid response data format:', response);
            }
        } catch (error) {
            console.error('Error fetching certificates:', error);
        }
    };
    useEffect(() => {
        fetchCategories();
    }, []);

    const handleChange =(e) =>{
        const { name, value } = e.target;
        setCategoryId(value);
        console.log(value,"xx")
    };

    const getCertificateByCategory = async()  =>{
        try {
            let response;
            console.log(categoryId,"mmm");
            if(categoryId==='') 
             response = await axios.get(`${process.env.REACT_APP_BASE_URL}/list/CertificatesByCategory/6673b428e2366b243033da98`);
            else
             response = await axios.get(`${process.env.REACT_APP_BASE_URL}/list/CertificatesByCategory/${categoryId}`);
            // console.log('Response:', response); // Log the response data to check its structure

            // Ensure the response data is an array and contains valid certificate objects
            if (Array.isArray(response)) {
                setCertificates(response);
            } else {
                console.error('Invalid response data format:', response);
            }
        } catch (error) {
            console.error('Error fetching certificates:', error);
            setCertificates([]);
        }
    };
    useEffect(() => {
        getCertificateByCategory();
    }, [categoryId]);
    document.title = 'EHS Certificate';
    return(
        
        // <>
        // <div  dangerouslySetInnerHTML={{__html:HTMLCONTENT}} />
        // {/* <div><h1>hi</h1></div> */}
        // </>
        // <>
        // {/* <iframe src = "./index.html" width="100%" height = "100%" title="Landing"></iframe> */}
        //     </>
        <>
        
        <body className="custom-cursor">

{/* <div className="custom-cursor__cursor"></div> */}
{/* <div className="custom-cursor__cursor-two"></div> */}


{/*<!-- /.preloader -->*/}
<div className="page-wrapper">
    <div className="home-main-header">
       
        <header className="main-header sticky-header sticky-header--normal">
            <div className="container-fluid">
                <div className="main-header__inner">
                    <div className="main-header__logo logo-retina">
                        <a href="index.html">
                            <img src={logoImg} alt=" " width="150"/>
                        </a>
                    </div>{/*<!-- /.main-header__logo -->*/}
                    <div className="main-header__right">
                       
                        <Link to = "/login" className="main-header__btn eduhive-btn eduhive-btn--border">
                            <span>Authority Login</span>
                            <span className="eduhive-btn__icon">
                                <span className="eduhive-btn__icon__inner"><i className="icon-right-arrow"></i></span>
                            </span>
                        </Link>{/*<!-- /.main-header__btn eduhive-btn -->*/}
                        <a href="http://clientehs.barodaweb.org" className="main-header__btn eduhive-btn eduhive-btn--border">
                            <span>Client Login</span>
                            <span className="eduhive-btn__icon">
                                <span className="eduhive-btn__icon__inner"><i className="icon-right-arrow"></i></span>
                            </span>
                        </a>{/*<!-- /.main-header__btn eduhive-btn -->*/}
                    </div>{/*<!-- /.main-header__right -->*/}
                </div>{/*<!-- /.main-header__inner -->*/}
            </div>{/*<!-- /.container-fluid -->*/}
        </header>{/*<!-- /.main-header -->*/}
    </div>{/*<!-- /.home-main-header -->*/}

    <section className="about-one py-4" id="about">
        {/* <div className="container">
            <img src={BannerIMG} />
        </div><!-- /.container --> */}
        <div className="container">
    <img src={BannerIMG} style={{ width: '100%', height: 'auto' }} />
</div>{/*<!-- /.container -->*/}

    </section>{/*<!-- /.about-one section-space -->*/}

    <section className="course-category section-50 background-grey">
        <div className="container">
            <div className="sec-title sec-title--center">
                

            <div className="row justify-content-center">

                <div className="col-lg-10">
                    <h5 className="sec-title__title h5">Search for Certificate</h5>{/*<!-- /.sec-title__title -->*/}
                    <p>Enhance your business and ensure compliance with EHS portal. Our platform offers a comprehensive range of industry certificates, providing seamless online services for everything from business incorporation to ongoing operational needs.</p>
                    <div className="row justify-content-center">
                        <div className="col-md-4">
                            <div className="checkout-page__control">
                                {/* <select className="selectpicker" >
                                    <option selected="">Select Categories</option>
                                    <option value="1">Startup</option>
                                    <option value="2">Company Registrations</option>
                                    <option value="3">Trademark</option>
                                    <option value="4">Goods & Services Tax</option>
                                    <option value="5">Income Tax</option>
                                    <option value="6">Compliance</option>
                                    <option value="7">Fire Safety</option>
                                </select> */}
                                <Input
                            type="select"
                            name="certificateId"
                            id="certificateId"
                            value={categoryId}
                            onChange={
                                handleChange
                            }
                            required
                        >
                            <option value="">Select Category</option>
                            {options.map((certificate) => (
                                <option key={certificate.value} value={certificate.value}>{certificate.label}</option>
                            ))}
                        </Input>
                            </div>
                        </div>

                    </div>
                </div>

            </div>
                
                
            </div>{/*<!-- /.sec-title -->*/}
            <div className="row gutter-y-30 my-row">
               { 
               
               certificates.map((certificate)=>{
                    return (
                        <>
                        {/* {certificates.length}{'xxx'} */}
                        <div className="col-xl-3 col-lg-4 col-sm-6 mt-4" >
                    <div className={`course-category__card course-category__card--5`}>
                        <div className="course-category__card__inner">
                            <div className="course-category__card__bg" style={{}}></div>
                        </div>
                        <div className="course-category__card__content">
                            <h4 className="course-category__card__title">{certificate.certificateName}</h4>
                        </div>
                    </div>
                </div>
                </>
                    )
                })}

                

                
               
            </div>{/*<!-- /.row gutter-y-30 -->*/}
        </div>{/*<!-- /.container -->*/}
       
    </section>{/*<!-- /.course-category section-space -->*/}


    <section className="about-two section-50 pb-0 bg-white">
        <div className="container">
            <div className="row gutter-y-60">
              
                <div className="col-lg-4">
                    <div className="about-two__content">
                        <div className="sec-title wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="00ms">
                            <h6 className="sec-title__tagline">ABOUT US</h6>{/*<!-- /.sec-title__tagline -->*/}
                            <h3 className="sec-title__title">EHS Certificate</h3>{/*<!-- /.sec-title__title -->*/}
                        </div>{/*<!-- /.sec-title -->*/}
                        <div className="about-two__description wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="00ms">
                            <p className="about-two__text text-justify">
                                Text will be coming soon...Text will be coming soon...Text will be coming soon...Text will be coming soon...Text will be coming soon...
                                Text will be coming soon...Text will be coming soon...Text will be coming soon...Text will be coming soon...Text will be coming soon...
                                Text will be coming soon...Text will be coming soon...Text will be coming soon...Text will be coming soon...Text will be coming soon...
                            </p>
                            <p className="about-two__text text-justify">
                                Text will be coming soon...Text will be coming soon...Text will be coming soon...Text will be coming soon...Text will be coming soon...
                                Text will be coming soon...Text will be coming soon...Text will be coming soon...Text will be coming soon...Text will be coming soon...
                            </p>
                        </div>
                    </div>{/*<!-- /.about-two__content -->*/}
                </div>{/*<!-- /.col-lg-6 -->*/}

                <div className="col-lg-8 wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="00ms">
                    <img src={EHSABOUT} className="img-fluid" /> 
                </div>{/*<!-- /.col-lg-6 -->*/}
                
            </div>{/*<!-- /.row gutter-y-60 -->*/}
        </div>{/*<!-- /.container -->*/}
       
    </section>{/*<!-- /.about-two section-space -->*/}

    

    <section className="">
        <div className="container">

        <div className="row">
            <div className="col-12">
                <img src={FOOTERBANNER} className="img-fluid" />
            </div>
        </div>

        </div>
        
    </section>


    

  

    <footer className="main-footer">
        
        <div className="main-footer__bottom">
            <div className="container">
                <div className="main-footer__bottom__inner row">

                <div className="col-md-6">
                    <p className="main-footer__copyright text-start">
                        &copy; <span className="dynamic-year"></span> All rights reserved. 
                    </p>
                </div>

                    <div className="col-md-6">
                        <p className="main-footer__copyright text-md-end">
                            Powered by : <a href="https://www.barodaweb.com/" target="_blank">Barodaweb | The E-catalogue Designer</a>
                        </p>
                    </div>
                    
                </div>
            </div>
        </div>
    </footer>

</div>



<a href="#" data-target="html" className="scroll-to-target scroll-to-top">
    <span className="scroll-to-top__text">back top</span>
    <span className="scroll-to-top__wrapper"><span className="scroll-to-top__inner"></span></span>
</a>

<script src="assets/vendors/jquery/jquery-3.7.0.min.js"></script>
<script src="assets/vendors/bootstrap/js/bootstrap.bundle.min.js"></script>
<script src="assets/vendors/bootstrap-select/bootstrap-select.min.js"></script>
<script src="assets/vendors/jarallax/jarallax.min.js"></script>
<script src="assets/vendors/jquery-ui/jquery-ui.js"></script>
<script src="assets/vendors/jquery-ajaxchimp/jquery.ajaxchimp.min.js"></script>
<script src="assets/vendors/jquery-appear/jquery.appear.min.js"></script>
<script src="assets/vendors/jquery-circle-progress/jquery.circle-progress.min.js"></script>
<script src="assets/vendors/jquery-magnific-popup/jquery.magnific-popup.min.js"></script>
<script src="assets/vendors/jquery-validate/jquery.validate.min.js"></script>
<script src="assets/vendors/nouislider/nouislider.min.js"></script>
<script src="assets/vendors/tiny-slider/tiny-slider.js"></script>
<script src="assets/vendors/wnumb/wNumb.min.js"></script>
<script src="assets/vendors/owl-carousel/js/owl.carousel.min.js"></script>
<script src="assets/vendors/owl-carousel/js/owlcarousel2-filter.min.js"></script>
<script src="assets/vendors/owl-carousel/js/owlcarousel2-progressbar.js"></script>
<script src="assets/vendors/wow/wow.js"></script>
<script src="assets/vendors/imagesloaded/imagesloaded.min.js"></script>
<script src="assets/vendors/isotope/isotope.js"></script>
<script src="assets/vendors/countdown/countdown.min.js"></script>
<script src="assets/vendors/jquery-circleType/jquery.circleType.js"></script>
<script src="assets/vendors/jquery-lettering/jquery.lettering.min.js"></script>

<script src="assets/js/eduhive.js"></script>
</body>

        </>
    )

}
export default Landing;