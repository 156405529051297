
import React, { useEffect, useState } from 'react';
import {Typography, Button, Dialog, DialogContent, DialogActions} from '@mui/material';
import axios from 'axios';
import DataTable from 'react-data-table-component';
import { toast, ToastContainer } from "react-toastify";
import AddCertificate from './AddCertificate';
import {useNavigate} from 'react-router-dom';
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

const MyServices = () => {
    const [agentName, setAgentName] = useState('Agent Name');
    const [agentId, setAgentId] = useState('');
    const [providingCertificates, setProvidingCertificates] = useState([]);
    const [allCertificates, setAllCertificates] = useState([]);
    const [matchedCertificates, setMatchedCertificates] = useState([]);
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [showModal, setShowModal] = useState(false);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [showResetConfirmation, setShowResetConfirmation] = useState(false);
    const [certificateToDelete, setCertificateToDelete] = useState(null);
    const [row, setRow] = useState(null);

    const navigate = useNavigate();

    const fetchAgentData = async () => {
        const agentId = localStorage.getItem('agentId');
        setAgentId(agentId);
        if (agentId) {
            try {
                const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/auth/list-by-params/certificatesWithTemplatesByAgent/${agentId}`,
                    {
                        // skip: (page - 1) * perPage,
                        skip: 0,
                        per_page: 10,
                        sorton: 'certificateName',
                        sortdir: 'asc',
                        // match: {}
                    });
                // console.log("sss", response)
                const agentData = response.agentCertificateWithTemplates;
                setAgentName(agentData.name || 'Agent Name');
                setProvidingCertificates(agentData || []);
            } catch (error) {
                console.error('Error fetching agent details:', error);
            }
        }
    };

    useEffect(() => {
        fetchAgentData();
        fetchCertificates();
    }, []);

    useEffect(() => {
        if (providingCertificates.length > 0 && allCertificates.length > 0) {
            const matched = allCertificates.filter(cert => providingCertificates.includes(cert._id));
            setMatchedCertificates(matched);
            setTotalRows(matched.length);
        }
    }, [providingCertificates, allCertificates]);

    const fetchCertificates = async () => {
        setLoading(true);
        try {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/list/certificateMaster`);
            setAllCertificates(response);
        } catch (error) {
            toast.error("Unable to fetch certificates");
            console.error('Error fetching certificates:', error);
        } finally {
            setLoading(false);
        }
    };

    const handlePageChange = page => {
        setCurrentPage(page);
    };

    const handlePerRowsChange = (newPerPage, page) => {
        setPerPage(newPerPage);
        setCurrentPage(page);
    };

    const toggleModal = () => {
        setShowModal(!showModal);
    };

    const confirmDelete = (rowId) => {
        setCertificateToDelete(rowId);
        setShowDeleteConfirmation(true);
    };
    const confirmReset = (row) => {
        setRow(row);
        setShowResetConfirmation(true);
    };

    const handleDelete = async () => {
        if (certificateToDelete) {
            const updatedCertificates = providingCertificates.filter(id => id.certificateId !== certificateToDelete);
            setProvidingCertificates(updatedCertificates);
    
            try {
                const updatedCertificatesString = JSON.stringify(updatedCertificates);
                // const data = updatedCertificates.map((e)=> e.certificateId).join(',');
                const data = updatedCertificates.map((e) => e.certificateId);
                await axios.put(`${process.env.REACT_APP_BASE_URL}/auth/update/agent/${agentId}`, {
                    providingCertificates: data,
                    deletedCert : certificateToDelete
                });
                toast.success("Certificate deleted successfully");
            } catch (error) {
                console.error('Error deleting certificate:', error);
                toast.error("Unable to delete certificate");
            } finally {
                setShowDeleteConfirmation(false);
                setCertificateToDelete(null);
            }
        }
    };
    
    

    const DeleteButton = ({ row }) => (
        <Button
            variant="contained"
            color="error"
            onClick={() => confirmDelete(row.certificateId)}
            style={{ height: '30px' , margin : 'auto' }}
        >
            Delete
        </Button>
    );

    const DesginCertificate = ({ row }) => (
        <Button
            variant="contained"
            color="primary"
            onClick={() => {
                localStorage.setItem('certId', row.certificateId);
                navigate('/designCertificate', { state: { certificateId: row.certificateId , certificateTemplate: row.template}})
            }}
            style={{ height: '30px' , margin: 'auto', width: '170px'}} // Adjust height as needed
        >
            {row.template === null ? "Create Template" : "Edit Template"}
        </Button>
    );

    const ResetTemplate = (row) => (
        <Button
            variant="contained"
            color="primary"
            onClick={() => confirmReset(row)}
            style={{ height: '30px' , margin: 'auto', width: '170px'}} // Adjust height as needed
        >
            Reset Template
        </Button>
    );

    const handleReset = async () => {
        console.log("Row:", row.row.template.templateID);
        try{
            const response = await axios.delete(`${process.env.REACT_APP_BASE_URL}/auth/delete/template/${row.row.template.templateID}`);
            if(response){
                toast.success("Template reset successfully");
            }
            else{
                toast.error("Error resetting template");
            }
        }
        catch (error) {
            console.error('Error resetting template:', error);
            toast.error("Unable to reset template");
        }
        finally {
            setShowResetConfirmation(false);
            setRow(null);
        }
    }

    const columns = [
        {
            name: 'Certificate Name',
            selector: row => row.certificateName,
            sortable: true,
        },
        // {
        //     name:"",
        //     selector:row => (<> <DesginCertificate row={row}/>  </>),
        //     button:true,
        //     width:'250px',
        //     right:true
        // },
        // {
        //     name: 'Manage Template',
        //     cell: row=>
        //         row.template===null? 'No Template':
        //     (<> <ResetTemplate row={row}/> </>),
        //     button: true,
        //     width: '250px',
        //     right: true,
        // },
        {
            name: 'Actions',
            cell: row =>  (<> <DeleteButton row={row} /> </>),
            button: true,
            width: '200px',
            right: true,
        },
    ];

    const currentPageData = matchedCertificates.slice(
        (currentPage - 1) * perPage,
        currentPage * perPage
    );
    document.title = " Services | EHS Certificate ";
    return (
        <div className="container mt-4" style={{ marginTop: "4rem", paddingTop: "4rem" }}>
            <div className="card">
                <div className="card-header d-flex justify-content-between align-items-center">
                    <h4>Providing Certificates</h4>
                    <Button color="success" className="add-btn me-1" onClick={toggleModal}>
                        <i className="ri-add-line align-bottom me-1"></i>
                        Add Certificate
                    </Button>
                </div>
                <div className="card-body">
                    <div className="table-responsive">
                        <DataTable
                            columns={columns}
                            data={providingCertificates}
                            progressPending={loading}
                            pagination
                            paginationServer
                            paginationTotalRows={totalRows}
                            paginationRowsPerPageOptions={[10, 50, 100]}
                            onChangeRowsPerPage={handlePerRowsChange}
                            onChangePage={handlePageChange}
                        />
                    </div>
                </div>
            </div>

            <Dialog open={showModal} onClose={toggleModal} maxWidth="md" fullWidth>
                <DialogContent>
                    <AddCertificate toggle={toggleModal} fetchAgentData={fetchAgentData} />
                </DialogContent>
            </Dialog>


            <Modal isOpen={showDeleteConfirmation} onClose={() => setShowDeleteConfirmation(false)}>
                <ModalHeader
                    className="bg-light p-3"
                >
                    <span style={{marginRight: "210px"}}>Remove Certificate</span>
                </ModalHeader>

                                                                        <form>
                                                                            <ModalBody>
                                                                                <div className="mt-2 text-center">
                                                                                    <lord-icon
                                                                                        src="https://cdn.lordicon.com/gsqxdxog.json"
                                                                                        trigger="loop"
                                                                                        colors="primary:#f7b84b,secondary:#f06548"
                                                                                        style={{
                                                                                            width: "100px",
                                                                                            height: "100px"
                                                                                        }}
                                                                                    ></lord-icon>
                                                                                    <div
                                                                                        className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
                                                                                        <h4>Are you sure ?</h4>
                                                                                        <p className="text-muted mx-4 mb-0">
                                                                                            Are you Sure You want to
                                                                                            Remove this Record ?
                                                                                        </p>
                                                                                    </div>
                                                                                </div>
                                                                            </ModalBody>
                                                                            <ModalFooter>
                                                                                <div
                                                                                    className="hstack gap-2 justify-content-end">
                                                                                    <button
                                                                                        type="submit"
                                                                                        className="btn btn-danger"
                                                                                        id="add-btn"
                                                                                        onClick={handleDelete}
                                                                                    >
                                                                                        Remove
                                                                                    </button>
                                                                                    <button
                                                                                        type="button"
                                                                                        className="btn btn-outline-danger"
                                                                                        onClick={() => setShowDeleteConfirmation(false)}
                                                                                    >
                                                                                        Close
                                                                                    </button>
                                                                                </div>
                                                                            </ModalFooter>
                                                                        </form>
                                                                    </Modal>

            <Dialog open={showResetConfirmation} onClose={() => setShowResetConfirmation(false)}>
                <DialogContent>
                    <Typography>Do you want to reset the template?</Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleReset} color="primary">Yes</Button>
                    <Button onClick={() => setShowResetConfirmation(false)} color="secondary">Cancel</Button>
                </DialogActions>
            </Dialog>

            <ToastContainer />
        </div>
    );
};

export default MyServices;

