import React, { useEffect, useState } from "react";
import { Button, Card, CardBody, Col, Container, Input, Label, Row } from "reactstrap";
import { Alert } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import logo from "../../assets/images/certificate/logo.jpg";

const initialState = {
    email: "",
    password: ""
};

const AgentLogin = () => {
    const navigate = useNavigate();
    useEffect(() => {
        const agentId = localStorage.getItem('agentId');
        console.log(agentId)
        if(agentId){
            navigate("/dashboard");
        }
    }, [])
    
    const { error } = useSelector((state) => ({
        error: state.Login.error,
    }));

    const [values, setValues] = useState(initialState);
    const [formErrors, setFormErrors] = useState({});
    const [isSubmit, setIsSubmit] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    

    const handleChange = (e) => {
        const { name, value } = e.target;
        setValues({ ...values, [name]: value });
    };
    

    const login = () => {
        setIsSubmit(true);
        const errors = validate(values);
        setFormErrors(errors);
        if (Object.keys(errors).length === 0) {
            axios
                .post(`${process.env.REACT_APP_BASE_URL}/login/agent`, values)
                .then((res) => {
                    if (res) {
                        if(res.agent.isActive === false){
                            toast.error("Your account is deactivated. Please contact the administrator.");
                            return;
                        }
                        else{
                            console.log('Login successful');

                            // Extract the agent data from the response
                            const agentData = res.agent;
                            const { _id } = agentData;
                            // Store the agent data in local storage
                            localStorage.setItem('agentId', _id);
                            console.log('Agent data stored in local storage');
                            console.log(agentData);

                            // Navigate to reports page on successful login
                            navigate("/dashboard");
                        }
                    } else {
                        toast.error("Authentication failed!");
                    }
                })
                .catch((err) => {
                    console.log(err);
                    toast.error("Authentication failed!");
                });
        }
    };

    const validate = (values) => {
        const errors = {};
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!values.email) {
            errors.email = "Email is required!";
        } else if (!regex.test(values.email)) {
            errors.email = "Invalid Email address!";
        }
        if (!values.password) {
            errors.password = "Password is required!";
        }
        return errors;
    };

    const validClassEmail =
        formErrors.email && isSubmit ? "form-control is-invalid" : "form-control";
    const validClassPassword =
        formErrors.password && isSubmit ? "form-control is-invalid" : "form-control pe-5";

    document.title = "SignIn | Project Name";

    return (
        <React.Fragment>
            <ToastContainer />
            <div className="auth-page-content">
                <Container>
                    <Row>
                        <Col lg={12}>
                            <div className="text-center mt-sm-5 mb-4 text-white-50"></div>
                        </Col>
                    </Row>

                    <Row className="justify-content-center">
                        <Col md={8} lg={6} xl={5}>
                            <Card style={{ marginTop: '15%' }}>
                                <CardBody className="p-4">
                                    <div className="text-center mt-2">
                                        <div
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                            }}
                                        >
                                            <div className="site-logo">
                                                <Link to="/">
                                                    <img
                                                        style={{ display: 'flex', alignItems: 'center' }}
                                                        src={logo}
                                                        height={'70px'}
                                                        width={'80px'}
                                                        alt="Project Name"
                                                    />
                                                </Link>
                                            </div>
                                        </div>
                                        <h5 className="text-primary mt-2">Welcome Back Authority!</h5>
                                        <p className="text-muted">Sign in to continue.</p>
                                    </div>
                                    {error && <Alert color="danger"> {error} </Alert>}
                                    <div className="p-2 mt-4">
                                        <div className="mb-3">
                                            <Label htmlFor="email" className="form-label">
                                                Email <span className="text-danger">*</span>
                                            </Label>
                                            <Input
                                                name="email"
                                                className={validClassEmail}
                                                placeholder="Enter email"
                                                type="email"
                                                onChange={handleChange}
                                                value={values.email}
                                            />
                                            {isSubmit && <p className="text-danger">{formErrors.email}</p>}
                                        </div>

                                        <div className="mb-3">
                                            <Label className="form-label" htmlFor="password-input">
                                                Password <span className="text-danger">*</span>
                                            </Label>
                                            <div className="position-relative auth-pass-inputgroup mb-3">
                                                <Input
                                                    name="password"
                                                    value={values.password}
                                                    type={showPassword ? 'text' : 'password'}
                                                    className={validClassPassword}
                                                    placeholder="Enter Password"
                                                    onChange={handleChange}
                                                />
                                                {isSubmit && <p className="text-danger">{formErrors.password}</p>}
                                                <button
                                                    className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted"
                                                    type="button"
                                                    id="password-addon"
                                                    onClick={() => setShowPassword(!showPassword)}
                                                >
                                                    {showPassword ? (
                                                        <i className="ri-eye-off-fill  align-middle"></i>
                                                    ) : (
                                                        <i className="ri-eye-fill align-middle"></i>
                                                    )}
                                                </button>
                                            </div>
                                        </div>

                                        <div className="mt-4">
                                            <Button
                                                color="success"
                                                className="btn btn-success w-100"
                                                type="submit"
                                                onClick={login}
                                            >
                                                Sign In
                                            </Button>
                                        </div>
                                    </div>
                                    <div className="mt-4 text-center">
                                        <p className="mb-0">
                                            Don't have an account?{' '}
                                            <Link
                                                to="/register"
                                                className="text-primary"
                                            >
                                                Sign Up
                                            </Link>
                                        </p>
                                    </div>
                                    <div className="mt-4 text-center">
                                        <p className="mb-0">
                                            <Link
                                                to="/forgot-password"
                                                className="text-primary"
                                            >
                                                Forgot Password?
                                            </Link>
                                        </p>
                                        </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default AgentLogin;
