import React, { useEffect, useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Form, FormGroup, Label, Input, FormFeedback } from 'reactstrap';
import axios from 'axios';
import { useDropzone } from 'react-dropzone';
import { Select } from 'antd';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom'; 
const IssueCertiAfterSave = ({ companyId, certificateId, agentId }) => {

    console.log('here babyy',companyId, certificateId, agentId)
    const [formData, setFormData] = useState({
        certificateId: '',
        issueDate: '',
        expireDate: '',
        remarks: '',
        agent:{},
        isActive: true
    });

    const [isLifetime, setisLifetime] = useState(false)

    const [file, setFile] = useState(null);
    const [loading, setLoading] = useState(false);
    const [options, setOptions] = useState([]);

    const navigate = useNavigate();


    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const formDataWithFile = new FormData();
            formDataWithFile.append('companyId', companyId);
            formDataWithFile.append('certificateId', certificateId);
            formDataWithFile.append('issueDate', formData.issueDate);
            formDataWithFile.append('expireDate', formData.expireDate);
            formDataWithFile.append('remarks', formData.remarks);
            formDataWithFile.append('isActive', true);
            formDataWithFile.append('agent',agentId);
            formDataWithFile.append('certificateFile', file);

            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/auth/create/certificateForCompany`, formDataWithFile, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            // onCertificateIssued(response.issuedCertificate);
            // toggleModal(); // Close the modal after successful issuance
            setFormData({
                certificateId: '',
                issueDate: '',
                expireDate: '',
                remarks: '',
                isActive: true,

            });
            setFile(null);
            navigate('/reports');
        } catch (error) {
            console.error('Error issuing certificate:', error);
            toast.error('Error Issuing certificate');
            // Handle error state
        } finally {
            setLoading(false);
            
            // toggleModal(); // Close the modal after issuancefile
        }
    };

    const [fileError, setFileError] = useState('');
    const { getRootProps, getInputProps } = useDropzone({
        accept: '.pdf, .jpg, .jpeg, .png',
        multiple: false,
        onDrop: (acceptedFiles) => {
            const selectedFile = acceptedFiles[0];
            if (selectedFile.size > 5242880) { // 5MB in bytes
              setFileError('File size should be a maximum of 5MB');
              setFile(null);
              toast.error('File size should be a maximum of 5MB');
            } else {
              setFile(selectedFile);
              setFileError('');
            }
        },
    });


    return (
        // <Modal isOpen={isOpen} toggle={toggleModal}>
        <Modal isOpen={true}>
            {/* <ModalHeader toggle={toggleModal}>Issue Certificate</ModalHeader> */}
            <ModalHeader >Issue Certificate</ModalHeader>

            <ModalBody>
                <Form onSubmit={handleSubmit}>
                    <FormGroup>
                        <Label for="issueDate">Issue Date</Label>
                        <Input
                            type="date"
                            name="issueDate"
                            id="issueDate"
                            value={formData.issueDate}
                            onChange={handleChange}
                            required
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label for="expireDate">Expiration Date</Label>
                        <Input
                            type="date"
                            name="expireDate"
                            id="expireDate"

                            value={(!isLifetime)? formData.expireDate : null}
                            onChange={handleChange}
                            disabled={isLifetime}
                        />
                    </FormGroup>
                    <FormGroup check>
                        <Label check>
                            <Input type="checkbox" checked={isLifetime} onChange={()=>{
                                if(!isLifetime){
                                    setFormData({...formData,expireDate:null})
                                }

                                setisLifetime(!isLifetime);

                            }} />{' '}
                            Lifetime
                        </Label>
                    </FormGroup>
                    <FormGroup>
                        <Label for="remarks">Remarks</Label>
                        <Input
                            type="textarea"
                            name="remarks"
                            id="remarks"
                            value={formData.remarks}
                            onChange={handleChange}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label for="certificateFile">Certificate File(max size 5MB)</Label>
                        <div {...getRootProps()} style={{ cursor: 'pointer', padding: '10px', border: '1px dashed #ccc' }}>
                            <input {...getInputProps()}/>
                            {file ? (
                                <p>{file.name}</p>
                            ) : (
                                <p>Drag 'n' drop file here, or click to select file</p>
                            )}
                        </div>

                    </FormGroup>
                    {fileError && <FormFeedback style={{ display: 'block' }}>{fileError}</FormFeedback>}
                    <ModalFooter>
                        <Button color="primary" type="submit" disabled={!certificateId || loading}>
                            {loading ? 'Issuing...' : 'Issue Certificate'}
                        </Button>
                    </ModalFooter>
                </Form>
            </ModalBody>
        </Modal>
    );
};

export default IssueCertiAfterSave;
