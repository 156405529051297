// import React, { useState, useEffect } from 'react';
// import {
//     AppBar, Toolbar, Typography, Container, TextField, Box, Grid,
//     Card, CardContent, Button, Modal, Backdrop, Fade,
//     InputAdornment,
//     IconButton
// } from '@mui/material';
// import axios from 'axios';
// import { toast, ToastContainer } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css'; // Import CSS for react-toastify
// import AddCertificate from './AddCertificate';
// import { Margin, Visibility, VisibilityOff } from '@mui/icons-material';

// const AgentProfile = () => {
//     const [agent, setAgent] = useState({
//         name: '',
//         phoneNumber: '',
//         email: '',
//         password: '',
//         providingCertificates: [],
//     });
//     const [initialAgent, setInitialAgent] = useState(null); // Store initial state for comparison
//     const [errors, setErrors] = useState({}); // Error state for validation
//     const [openAddCertificateModal, setOpenAddCertificateModal] = useState(false);
//     const [showPassword, setShowPassword] = useState(false);

//     useEffect(() => {
//         const fetchAgentData = async () => {
//             const agentId = localStorage.getItem('agentId');
//             if (agentId) {
//                 try {
//                     const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/auth/get/agentById/${agentId}`, {match:""});
//                     const agentData = response.agent; // Ensure you access the correct property
//                     setAgent({
//                         name: agentData.name || '',
//                         phoneNumber: agentData.mobileNumber || '',
//                         email: agentData.email || '',
//                         password:agentData.password ||'',
//                         providingCertificates: agentData.providingCertificates || [],
//                     });
//                     setInitialAgent({
//                         name: agentData.name || '',
//                         phoneNumber: agentData.mobileNumber || '',
//                         email: agentData.email || '',
//                         password: agentData.password || '',
//                         providingCertificates: agentData.providingCertificates || [],
//                     });
//                     // toast.success("Agent data fetched successfully");
//                 } catch (error) {
//                     toast.error("Unable to fetch agent data");
//                     console.error('Error fetching agent details:', error);
//                 }
//             }
//         };

//         fetchAgentData();
//     }, []);

//     const validate = () => {
//         const newErrors = {};
//         if (!agent.name.trim()) newErrors.name = 'Agent name is required.';
//         if (!agent.phoneNumber.trim()) newErrors.phoneNumber = 'Phone number is required.';
//         if (!agent.email.trim()) newErrors.email = 'Email is required.';
//         if (!agent.password.trim()) newErrors.password = 'Password is required.';
//         setErrors(newErrors);
//         return Object.keys(newErrors).length === 0;
//     };

//     const handleInputChange = (e) => {
//         const { name, value } = e.target;
//         setAgent({ ...agent, [name]: value });
//     };

//     const hasChanges = () => {
//         return (
//             agent.name !== initialAgent.name ||
//             agent.phoneNumber !== initialAgent.phoneNumber ||
//             agent.email !== initialAgent.email ||
//             agent.password !== initialAgent.password
//         );
//     };

//     const handleUpdate = () => {
//         if (!validate()) {
//             return;
//         }
//         if (!hasChanges()) {
//             toast.error("Change the values to update the profile");
//             return;
//         }
//         const agentId = localStorage.getItem('agentId');
//         axios.put(`${process.env.REACT_APP_BASE_URL}/auth/update/agent/${agentId}`, {
//             name: agent.name,
//             mobileNumber: agent.phoneNumber,
//             email: agent.email,
//             password: agent.password
//         })
//         .then(response => {
//             toast.success("Profile updated successfully");
//             console.log('Agent details updated successfully:', response.data);
//         })
//         .catch(error => {
//             toast.error("Unable to update profile");
//             console.error('Error updating agent details:', error);
//         });
//     };

//     const handleOpenAddCertificateModal = () => {
//         setOpenAddCertificateModal(true);
//     };

//     const handleCloseAddCertificateModal = () => {
//         setOpenAddCertificateModal(false);
//     };
//     const handleClickShowPassword = () => {
//         setShowPassword(!showPassword);
//     };

//     const handleMouseDownPassword = (event) => {
//         event.preventDefault();
//     };

//     const handleAddCertificate = (certificateName) => {
//         const updatedCertificates = [...agent.providingCertificates, { _id: Math.random().toString(), certificateName }];
//         setAgent({ ...agent, providingCertificates: updatedCertificates });
//         handleCloseAddCertificateModal(); // Close modal after adding certificate
//     };

//     document.title = "Authority Profile | EHS Certificate";

//     return (
//         <div>
//             <AppBar position="static" sx={{ backgroundColor: 'white', color: 'black' }}>
//                 <Toolbar>
//                     <Typography variant="h6" component="div" sx={{ flexGrow: 1, textAlign: 'center', fontWeight: 'bold' }}>
//                         Authority Details
//                     </Typography>
//                 </Toolbar>
//             </AppBar>
//             <Container>
//                 <Box display="flex" flexDirection="column" alignItems="center" mt={5}>
//                     <Card sx={{
//                         maxWidth: 600,
//                         boxShadow: 3,
//                     }}>
//                         <CardContent>
//                             <Grid container spacing={2}>
//                                 <Grid item xs={12}>
//                                     <TextField
//                                         label="Authority Name"
//                                         variant="outlined"
//                                         fullWidth
//                                         name="name"
//                                         value={agent.name}
//                                         onChange={handleInputChange}
//                                         sx={{ maxWidth: '100%' }}
//                                         error={!!errors.name}
//                                         helperText={errors.name}
//                                     />
//                                 </Grid>
//                                 <Grid item xs={12}>
//                                     <TextField
//                                         label="Phone Number"
//                                         variant="outlined"
//                                         fullWidth
//                                         name="phoneNumber"
//                                         value={agent.phoneNumber}
//                                         onChange={handleInputChange}
//                                         sx={{ maxWidth: '100%' }}
//                                         error={!!errors.phoneNumber}
//                                         helperText={errors.phoneNumber}
                                        
//                                     />
//                                 </Grid>
//                                     <Grid item xs={12}>
//                                     <TextField
//                                     type={showPassword ? 'text' : 'password'}
//                                         label="Password"
//                                         variant="outlined"
//                                         fullWidth
//                                         name="password"
//                                         value={agent.password}
//                                         onChange={handleInputChange}
//                                         sx={{ maxWidth: '100%' }}
//                                         error={!!errors.password}
//                                         helperText={errors.password}
//                                         InputProps={{
//                                             endAdornment: (
//                                                 <InputAdornment position="end">
//                                                     <IconButton
//                                                         aria-label="toggle password visibility"
//                                                         onClick={handleClickShowPassword}
//                                                         onMouseDown={handleMouseDownPassword}
//                                                         edge="end"
//                                                     >
//                                                         {showPassword ? <Visibility /> : <VisibilityOff />}
//                                                     </IconButton>
//                                                 </InputAdornment>
//                                             ),
//                                         }}
//                                     />
//                                 </Grid>
//                                 <Grid item xs={12}>
//                                     <TextField
//                                         label="Email"
//                                         variant="outlined"
//                                         fullWidth
//                                         name="email"
//                                         value={agent.email}
//                                         onChange={handleInputChange}
//                                         sx={{ maxWidth: '100%' }}
//                                         error={!!errors.email}
//                                         helperText={errors.email}
//                                     />
//                                 </Grid>
//                                 <Grid item xs={12}>
//                                     <Button
//                                         variant="contained"
//                                         color="primary"
//                                         fullWidth
//                                         onClick={handleUpdate}
//                                     >
//                                         Update
//                                     </Button>
//                                 </Grid>
//                             </Grid>
//                         </CardContent>
//                     </Card>
//                 </Box>
//             </Container>
//             <ToastContainer />
//         </div>
//     );
// };

// export default AgentProfile;
import React, { useState, useEffect } from 'react';
import {
    AppBar, Toolbar, Typography, Container, TextField, Box, Grid,
    Card, CardContent, Button, IconButton, InputAdornment
} from '@mui/material';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Visibility, VisibilityOff } from '@mui/icons-material';

const AgentProfile = () => {
    const [agent, setAgent] = useState({
        name: '',
        phoneNumber: '',
        email: '',
        password: '',
        providingCertificates: [],
    });
    const [initialAgent, setInitialAgent] = useState(null);
    const [errors, setErrors] = useState({});
    const [openAddCertificateModal, setOpenAddCertificateModal] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    useEffect(() => {
        const fetchAgentData = async () => {
            const agentId = localStorage.getItem('agentId');
            if (agentId) {
                try {
                    const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/auth/get/agentById/${agentId}`, { match: "" });
                    const agentData = response.agent;
                    setAgent({
                        name: agentData.name || '',
                        phoneNumber: agentData.mobileNumber || '',
                        email: agentData.email || '',
                        password: agentData.password || '',
                        providingCertificates: agentData.providingCertificates || [],
                    });
                    setInitialAgent({
                        name: agentData.name || '',
                        phoneNumber: agentData.mobileNumber || '',
                        email: agentData.email || '',
                        password: agentData.password || '',
                        providingCertificates: agentData.providingCertificates || [],
                    });
                } catch (error) {
                    toast.error("Unable to fetch agent data");
                    console.error('Error fetching agent details:', error);
                }
            }
        };

        fetchAgentData();
    }, []);

    const validate = () => {
        const newErrors = {};
        if (!agent.name.trim()) newErrors.name = 'Agent name is required.';
        if (!agent.phoneNumber.trim()) {
            newErrors.phoneNumber = 'Phone number is required.';
        } else if (!/^\d{10}$/.test(agent.phoneNumber)) {
            newErrors.phoneNumber = 'Phone number must be 10 digits.';
        }
        if (!agent.email.trim()) {
            newErrors.email = 'Email is required.';
        } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(agent.email)) {
            newErrors.email = 'Invalid email format.';
        }
        if (!agent.password.trim()) {
            newErrors.password = 'Password is required.';
        } else if (agent.password.length < 6) {
            newErrors.password = 'Password must be at least 6 characters.';
        }
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setAgent({ ...agent, [name]: value });
    };

    const hasChanges = () => {
        return (
            agent.name !== initialAgent.name ||
            agent.phoneNumber !== initialAgent.phoneNumber ||
            agent.email !== initialAgent.email ||
            agent.password !== initialAgent.password
        );
    };

    const handleUpdate = () => {
        if (!validate()) {
            return;
        }
        if (!hasChanges()) {
            toast.error("Change the values to update the profile");
            return;
        }
        const agentId = localStorage.getItem('agentId');
        axios.put(`${process.env.REACT_APP_BASE_URL}/auth/update/agent/${agentId}`, {
            name: agent.name,
            mobileNumber: agent.phoneNumber,
            email: agent.email,
            password: agent.password
        })
        .then(response => {
            toast.success("Profile updated successfully");
            console.log('Agent details updated successfully:', response.data);
        })
        .catch(error => {
            toast.error("Unable to update profile");
            console.error('Error updating agent details:', error);
        });
    };

    const handleOpenAddCertificateModal = () => {
        setOpenAddCertificateModal(true);
    };

    const handleCloseAddCertificateModal = () => {
        setOpenAddCertificateModal(false);
    };

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleAddCertificate = (certificateName) => {
        const updatedCertificates = [...agent.providingCertificates, { _id: Math.random().toString(), certificateName }];
        setAgent({ ...agent, providingCertificates: updatedCertificates });
        handleCloseAddCertificateModal();
    };

    document.title = "Authority Profile | EHS Certificate";

    return (
        <div>
            <AppBar position="static" sx={{ backgroundColor: 'white', color: 'black' }}>
                <Toolbar>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1, textAlign: 'center', fontWeight: 'bold' }}>
                        Authority Details
                    </Typography>
                </Toolbar>
            </AppBar>
            <Container>
                <Box display="flex" flexDirection="column" alignItems="center" mt={5}>
                    <Card sx={{ maxWidth: 600, boxShadow: 3 }}>
                        <CardContent>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <TextField
                                        label="Authority Name"
                                        variant="outlined"
                                        fullWidth
                                        name="name"
                                        value={agent.name}
                                        onChange={handleInputChange}
                                        sx={{ maxWidth: '100%' }}
                                        error={!!errors.name}
                                        helperText={errors.name}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        label="Phone Number"
                                        variant="outlined"
                                        fullWidth
                                        name="phoneNumber"
                                        value={agent.phoneNumber}
                                        onChange={handleInputChange}
                                        sx={{ maxWidth: '100%' }}
                                        error={!!errors.phoneNumber}
                                        helperText={errors.phoneNumber}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        type={showPassword ? 'text' : 'password'}
                                        label="Password"
                                        variant="outlined"
                                        fullWidth
                                        name="password"
                                        value={agent.password}
                                        onChange={handleInputChange}
                                        sx={{ maxWidth: '100%' }}
                                        error={!!errors.password}
                                        helperText={errors.password}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowPassword}
                                                        onMouseDown={handleMouseDownPassword}
                                                        edge="end"
                                                    >
                                                        {showPassword ? <Visibility /> : <VisibilityOff />}
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        label="Email"
                                        variant="outlined"
                                        fullWidth
                                        name="email"
                                        value={agent.email}
                                        onChange={handleInputChange}
                                        sx={{ maxWidth: '100%' }}
                                        error={!!errors.email}
                                        helperText={errors.email}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        fullWidth
                                        onClick={handleUpdate}
                                    >
                                        Update
                                    </Button>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Box>
            </Container>
            <ToastContainer />
        </div>
    );
};

export default AgentProfile;
