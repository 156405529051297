import React, { useEffect, useState } from 'react';
import { Button, Dialog, DialogContent, DialogActions, DialogTitle, Typography, TextField } from '@mui/material'; // Ensure DialogTitle and DialogActions are imported from '@mui/material'
import axios from 'axios';
import DataTable from 'react-data-table-component';
import { toast, ToastContainer } from "react-toastify";
import AddField from './AddField';
import EditFields from './EditFields'; // Import EditFields component

const FieldsTable = () => {
    const [agentName, setAgentName] = useState('Agent Name');
    const [providingCertificates, setProvidingCertificates] = useState([]);
    const [certificateDetails, setCertificateDetails] = useState([]);
    const [matchedCertificates, setMatchedCertificates] = useState([]);
    const [selectedCertificate, setSelectedCertificate] = useState('');
    const [fieldsData, setFieldsData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [showModal, setShowModal] = useState(false);
    const [deleteConfirmation, setDeleteConfirmation] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [editModalOpen, setEditModalOpen] = useState(false); // State for EditFields modal
    const [selectedField, setSelectedField] = useState(null); // State to store selected field for editing
    const agentId = localStorage.getItem('agentId');

    const fetchAgentData = async () => {
        if (agentId) {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/auth/get/agentById/${agentId}`);
                const agentData = response.agent; // Corrected accessing response.data
                setAgentName(agentData.name);
                setProvidingCertificates(agentData.providingCertificates || []);
            } catch (error) {
                console.error('Error fetching agent details:', error);
            }
        }
    };

    useEffect(() => {
        fetchAgentData();
        fetchCertificates();
    }, []);

    useEffect(() => {
        if (providingCertificates.length > 0 && certificateDetails.length > 0) {
            const matched = certificateDetails.filter(cert => providingCertificates.some(p => p._id === cert._id));
            setMatchedCertificates(matched);
        }
    }, [providingCertificates, certificateDetails]);

    useEffect(() => {
        fetchFieldsData();
    }, [searchTerm, currentPage, perPage]);

    const fetchCertificates = async () => {
        setLoading(true);
        try {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/list/certificateMaster`);
            setCertificateDetails(response); // Corrected setting state with response.data
        } catch (error) {
            toast.error("Unable to fetch certificates");
            console.error('Error fetching certificates:', error);
        } finally {
            setLoading(false);
        }
    };

    const fetchFieldsData = async () => {
        setLoading(true);
        try {
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/auth/list-by-params/field/${agentId}`, {
                skip: (currentPage - 1) * perPage,
                per_page: perPage,
                sorton: 'createdAt',
                sortdir: 'desc',
                match: searchTerm,
                isActive: true
            });
            setFieldsData(response.data);
            setTotalRows(response.total);
        } catch (error) {
            console.error('Error fetching fields:', error);
            toast.error("Unable to fetch fields");
        } finally {
            setLoading(false);
        }
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handlePerRowsChange = (newPerPage, page) => {
        setPerPage(newPerPage);
        setCurrentPage(page);
    };

    const toggleModal = () => {
        setShowModal(!showModal);
    };

    const toggleEditModal = () => {
        setEditModalOpen(!editModalOpen);
    };

    const handleCertificateChange = (event) => {
        setSelectedCertificate(event.target.value);
        setCurrentPage(1);
    };

    const handleDeleteField = async () => {
        if (deleteConfirmation) {
            try {
                await axios.delete(`${process.env.REACT_APP_BASE_URL}/auth/delete/field/${deleteConfirmation}`);
                toast.success("Field deleted successfully");
                fetchFieldsData(selectedCertificate);
                setDeleteConfirmation(null);
            } catch (error) {
                console.error('Error deleting field:', error);
                toast.error("Unable to delete field");
            }
        }
    };

    const openDeleteConfirmation = (fieldId) => {
        setDeleteConfirmation(fieldId);
    };

    const closeDeleteConfirmation = () => {
        setDeleteConfirmation(null);
    };

    const handleFieldAdded = () => {
        fetchFieldsData(selectedCertificate);
    };

    const openEditModal = (fieldData) => {
        setSelectedField(fieldData);
        toggleEditModal();
    };

    const handleFieldUpdated = () => {
        fetchFieldsData(selectedCertificate);
        toggleEditModal();
    };

    const DeleteButton = ({ row }) => (
        <Button
            variant="contained"
            color="error"
            onClick={() => openDeleteConfirmation(row._id)}
            style={{ height: '30px', margin: 'auto' }}
        >
            Delete
        </Button>
    );

    const EditButton = ({ row }) => (
        <Button
            variant="contained"
            color="primary"
            onClick={() => openEditModal(row)}
            style={{ height: '30px', margin: 'auto' }}
        >
            Edit
        </Button>
    );

    const columns = [
        {
            name: 'Field Name',
            selector: row => row.fieldName,
            sortable: true,
            style: {
                paddingRight: '0px', // Adjust the padding to reduce the gap
            },
            maxWidth: '200px',
        },
        {
            name: 'Field Key',
            selector: row => row.key,
            sortable: true,
            style: {
                paddingRight: '0px', // Adjust the padding to reduce the gap
            },
            maxWidth: '200px',
        },
        {
            name: 'Field Type',
            selector: row => row.fieldType,
            sortable: true,
            style: {
                paddingRight: '0px', // Adjust the padding to reduce the gap
            },
            maxWidth: '200px',
        },
        {
            name: 'Certificates',
            cell: (row) => (
                <ul style={{ paddingLeft: '1px' }}>
                    {row.certificates.map((cert, index) => (
                        <li key={index}>{cert.certificateName}</li>
                    ))}
                </ul>
            ),
            sortable: true,
            maxWidth: '350px',
        },
        {
            name: 'Actions',
            cell: row => (<><EditButton row={row} /><DeleteButton row={row} /></>),
            button: true,
            width: '200px',
            right: true,
        },  
    ];

    document.title = "Fields Table | EHS Certificate";

    const filteredData = fieldsData.filter(item =>
        item.fieldName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.certificates.some(cert => cert.certificateName.toLowerCase().includes(searchTerm.toLowerCase()))
    );

    return (
        <div className="container mt-4" style={{ marginTop: "4rem", paddingTop: "4rem" }}>
            <div className="card">
                <div className="card-header d-flex justify-content-between align-items-center">
                    <Typography variant="h4">My Fields</Typography>
                    <Button color="success" className="add-btn me-1" onClick={toggleModal}>
                        <i className="ri-add-line align-bottom me-1"></i>
                        Add Field
                    </Button>
                </div>
                <div className="card-body">
                    <TextField
                        label="Search by Field Name or Certificate Name"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                    <div className="table-responsive mt-3">
                        <DataTable
                            columns={columns}
                            data={filteredData}
                            progressPending={loading}
                            pagination
                            paginationServer
                            paginationTotalRows={totalRows}
                            paginationRowsPerPageOptions={[10, 50, 100]}
                            onChangeRowsPerPage={handlePerRowsChange}
                            onChangePage={handlePageChange}
                            customStyles={{
                                rows: {
                                    style: {
                                        minHeight: '72px', // override the row height
                                    },
                                },
                                headCells: {
                                    style: {
                                        paddingLeft: '8px', // override the head cell padding
                                        paddingRight: '8px',
                                    },
                                },
                                cells: {
                                    style: {
                                        paddingLeft: '8px', // override the cell padding
                                        paddingRight: '8px',
                                    },
                                },
                            }}
                        />
                    </div>
                </div>
            </div>

            <Dialog
                open={deleteConfirmation !== null}
                onClose={closeDeleteConfirmation}
                maxWidth="xs"
                fullWidth
            >
                <DialogTitle>Delete Field</DialogTitle>
                <DialogContent>
                    <Typography variant="body1">
                        Are you sure you want to delete this field?
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeDeleteConfirmation} color="primary">
                        Cancel
                    </Button>
                    <Button
                        onClick={handleDeleteField}
                        color="error"
                    >
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={showModal} onClose={toggleModal} maxWidth="md" fullWidth>
                <DialogContent>
                    <AddField toggle={toggleModal} onFieldAdded={handleFieldAdded} />
                </DialogContent>
            </Dialog>

            {/* Edit Fields Modal */}
            <Dialog open={editModalOpen} onClose={toggleEditModal} maxWidth="md" fullWidth>
                <DialogContent>
                    <EditFields
                        toggle={toggleEditModal}
                        prefixData={selectedField} // Pass selected field data to EditFields component
                        onPrefixUpdated={handleFieldUpdated} // Callback to refresh fieldsData after update
                        providingCertificates={providingCertificates} // Pass providingCertificates to EditFields component
                    />
                </DialogContent>
            </Dialog>

            <ToastContainer />
        </div>
    );
};

export default FieldsTable;
