import React from "react";
import { Navigate } from "react-router-dom";

import MyServices from "../pages/AgentPanelV/MyServices";
import AgentProfile from "../pages/AgentPanelV/AgentProfile";
import Reports from "../pages/AgentPanelV/Reports";
import MyClients from "../pages/AgentPanelV/MyClients"
import AgentLogin from "../pages/AgentPanelAuth/AgentLogin";
import AgentRegister from "../pages/AgentPanelAuth/AgentRegister";
import Dashboard from "../pages/AgentPanelV/Dashboard";
import PolotnoCanvas from "../pages/AgentPanelV/PolotnoCanvas";
import FieldsTable from "../pages/AgentPanelV/FieldsTable";
import MyPrefix from "../pages/AgentPanelV/MyPrefix";
import PolotnoLoadedCanvas from "../pages/AgentPanelV/confirmCertificateinPolotno";
import Landing from "../pages/landingMain/landing";
import CoverPasswCreate from "../pages/AgentPanelAuth/CoverPasswCreate";
import ForgetPassword from "../pages/AgentPanelAuth/ForgetPasswordPage";

const authProtectedRoutes = [
  { path: "/my-services", component: <MyServices/>},
  { path: "/dashboard", component: <Dashboard/>},
  { path: "/agent-profile", component:<AgentProfile/>},
  { path: "/reports", component:<Reports/>},
  { path: "/my-clients", component:<MyClients/>},
  { path: "/designCertificate", component:<PolotnoCanvas/>},
  { path: "/fields-table", component:<FieldsTable/>},
  { path: "/my-prefix", component:<MyPrefix/>},
  { path: "/confirm-certificate",component:<PolotnoLoadedCanvas/>},
// remove this line

  
  { path: "*", component: <Navigate to="/my-services" /> },
];

const publicRoutes = [
  {path: "/login", component:<AgentLogin/>},
  {path: "/register", component:<AgentRegister/>},
  {path:"/",component:<Landing/>},
  { path: "/forgot-password", component: <ForgetPassword/> },
  { path: "/reset-password", component: <CoverPasswCreate/>},
  { path: "*", component: <Navigate to="/" /> },
];

export { authProtectedRoutes, publicRoutes };
