import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast, ToastContainer } from "react-toastify";
import { Button, Box, Grid, Typography } from '@mui/material';
import SearchableDropdown from './SearchableDropdown';
import 'react-toastify/dist/ReactToastify.css';

const AddCertificate = ({ toggle, fetchAgentData }) => {
    const [selectedCertificates, setSelectedCertificates] = useState([]);
    const [agentId, setAgentId] = useState('');
    const [errors, setErrors] = useState({});

    useEffect(() => {
        const agentId = localStorage.getItem('agentId');
        if (agentId) {
            setAgentId(agentId);
        }
    }, []);

    const validate = () => {
        const newErrors = {};
        if (selectedCertificates.length === 0) {
            newErrors.selectedCertificates = 'Please select at least one certificate.';
        }
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = async () => {
        if (!validate()) {
            return;
        }
        try {
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/auth/update/addProvidingCertificates`, {
                agentId,
                certificateIds: selectedCertificates
            });
            console.log('Response:', response.data);
            setSelectedCertificates([]);
            fetchAgentData(); // Fetch the updated agent data after submission
            toggle();
            toast.success("Certificate added successfully"); // Close the modal
        } catch (error) {
            console.error('Error adding providing certificates:', error);
            toast.error("Error adding certificate");
        }
    };

    return (
        <Box display="flex" flexDirection="column" alignItems="center" mt={4} height={'28rem'}>
            <Typography variant="h4" component="div" sx={{ textAlign: 'center', fontWeight: 'bold' }}>
                Add Certificates
            </Typography>
            <Grid container spacing={2} mt={3} maxWidth="600px">
                <Grid item xs={12}>
                    <SearchableDropdown
                        selectedCertificates={selectedCertificates}
                        setSelectedCertificates={setSelectedCertificates}
                    />
                    {errors.selectedCertificates && (
                        <Typography color="error" variant="body2">
                            {errors.selectedCertificates}
                        </Typography>
                    )}
                </Grid>
                <Grid item xs={12}>
                    <Button
                        variant="contained"
                        fullWidth
                        sx={{ backgroundColor: 'black', color: 'white', textTransform: 'none' }}
                        onClick={handleSubmit}
                    >
                        Submit
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    <Button
                        variant="outlined"
                        fullWidth
                        sx={{ textTransform: 'none' }}
                        onClick={toggle}
                    >
                        Cancel
                    </Button>
                </Grid>
            </Grid>
            <ToastContainer />
        </Box>
    );
};

export default AddCertificate;
