import React, { useContext, useEffect, useState } from "react";
import { PolotnoContainer, SidePanelWrap, WorkspaceWrap } from "polotno";
import { Toolbar } from "polotno/toolbar/toolbar";
import { ZoomButtons } from "polotno/toolbar/zoom-buttons";
import { SidePanel, DEFAULT_SECTIONS } from "polotno/side-panel";
import { Workspace } from "polotno/canvas/workspace";

import "@blueprintjs/icons/lib/css/blueprint-icons.css";
import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/popover2/lib/css/blueprint-popover2.css";

import { createStore } from "polotno/model/store";
import { Container, Row, Col, Button } from "reactstrap";
import { DownloadButton } from "polotno/toolbar/download-button";
import { observer } from "mobx-react-lite";
import FaShapes from "@meronex/icons/fa/FaShapes";
import { SectionTab } from "polotno/side-panel";
import axios from "axios";
import {useLocation, useNavigate} from "react-router-dom";
import {toast, ToastContainer } from 'react-toastify';
import IssueCertiAfterSave from "./IssueCertiAfterSave";

// let certId;
let agentID;
const store = createStore({
    key: "nFA5H9elEytDyPyvKL7T",
    showCredit: true,
});
store.addPage();

const ActionControls = ({ store, onExportSVG }) => {
    return (
        <div>
            {/*/!* <DownloadButton store={store} /> *!/---------------------------------*/}
            <Button color="primary" onClick={onExportSVG} className="ml-2">
                Save Certificate
            </Button>
        </div>
    );
};

const CustomToolbar = ({ store, onExportSVG }) => {
    return (
        <Toolbar
            store={store}
            components={{
                ActionControls: (props) => <ActionControls {...props} onExportSVG={onExportSVG} />,
            }}
        />
    );
};

const CustomSection = {
    name: "custom",
    Tab: (props) => (
        <SectionTab name="Custom" {...props}>
            <FaShapes icon="new-text-box" />
        </SectionTab>
    ),
    Panel: observer(({ store }) => {
        const addTextElement = (text) => {
            store.activePage.addElement({
                type: "text",
                text: text,
                x: store.width / 2,
                y: store.height / 2,
            });
        };
        const [fields, setfields] = useState(null);
        const fetchFields = async ()=>{
            // console.log("www", certId);
            const certId = localStorage.getItem('certId');
            console.log("certId", certId);
            try{
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/auth/list-by-params/field/${agentID}`,{
                certId:certId,
                skip:0,
                per_page:100,
                sorton:"fieldsName",
                sortdir:"asc",
                match:"",
                isActive:true},)
                
                setfields(response.data);
            }
            catch(error){
                console.log(error);
            }
                
        };

        useEffect(() => {
            fetchFields();
        },[])
        return (
            <div>
                <h3>Custom Elements</h3>

            <div style={{display: "flex", flexWrap: "wrap"}}>
                {
                    fields && fields.map((field) => {
                        return (
                            <Button key={field.key} style={{margin: "8px"}} onClick={() => addTextElement(field.key)}>
                                {field.key}
                            </Button>
                        );
                    })
                }
            </div>
            </div>
        );
    }),
};

const sections = [CustomSection, ...DEFAULT_SECTIONS];

const CustomSidePanel = () => {
    return <SidePanel store={store} sections={sections} defaultSection="custom" />;
};

const PolotnoLoadedCanvas = ({props}) => {
    document.title = "Design Certificate | EHS";

    const location = useLocation();
    const [companyId, setCompanyId] = useState('');
    const [certificateId, setCertificateId] = useState('');

    useEffect(() => {
        if(location!==null) {
            console.log('first',location.state);
            const responseData = location.state.certificateResponse;
            store.loadJSON(responseData.certificate.file);
            setCompanyId(responseData.certificate.company);
            setCertificateId(responseData.certificate.certificate);
        }
    }, [location.state]);

    
    const [stage, setStage] = useState(null);

    const navigate = useNavigate();

    const agentId = localStorage.getItem('agentId');
    agentID = localStorage.getItem('agentId');

    useEffect(() => {
        console.log("Stage:", stage);
    }, [stage]);

    const [showIssueCerti, setShowIssueCerti] = useState(false);
    const handleExportSVG = async () => {
        await store.saveAsPDF();
        setShowIssueCerti(true);  
        // <IssueCertiAfterSave agentId={agentId} companyId={companyId} certificateId={certificateId} />

    };
    return (
        <div className="page-content">
            <Container fluid>
                <Row>
                    <Col xs={12}>
                    {!showIssueCerti ? (
                        <PolotnoContainer style={{ width: "100%", height: "100vh" }}>
                            <SidePanelWrap>
                                <CustomSidePanel />
                            </SidePanelWrap>
                            <WorkspaceWrap>
                                <CustomToolbar  store={store} onExportSVG={handleExportSVG} />
                                <Workspace store={store} onReady={(stage) => setStage(stage)} />
                                <ZoomButtons store={store} />
                            </WorkspaceWrap>
                        </PolotnoContainer>
                        ) : (
                            <IssueCertiAfterSave agentId={agentId} companyId={companyId} certificateId={certificateId} />
                        )}
                    </Col>
                </Row>
            </Container>
            <ToastContainer />
        </div>
    );
};

export default PolotnoLoadedCanvas;


