import React, { useEffect, useState } from 'react';
import { Typography, Button } from '@mui/material';
import axios from 'axios';
import DataTable from 'react-data-table-component';
import { toast, ToastContainer } from "react-toastify";
import AddPrefix from './AddPrefix';
import EditPrefix from './EditPrefix';
import { useNavigate } from 'react-router-dom';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

const MyPrefix = () => {
    const [agentName, setAgentName] = useState('Agent Name');
    const [prefixes, setPrefixes] = useState([]);
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [showAddModal, setShowAddModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [modalDelete, setModalDelete] = useState(false);
    const [prefixToDelete, setPrefixToDelete] = useState(null);
    const [prefixToEdit, setPrefixToEdit] = useState(null);
    const agentId = localStorage.getItem('agentId');
    const navigate = useNavigate();

    const fetchAgentData = async () => {
        if (agentId) {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/auth/get/agentById/${agentId}`);
                const agentData = response.agent; // Assuming response.data.agent contains the agent details
                setAgentName(agentData.name || 'Agent Name');
            } catch (error) {
                console.error('Error fetching agent details:', error);
            }
        }
    };

    useEffect(() => {
        fetchAgentData();
        fetchPrefixes();
    }, []);

    const fetchPrefixes = async () => {
        setLoading(true);
        try {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/auth/list/prefix/${agentId}`);
            setPrefixes(response); // Assuming response.data is an array of prefixes
            setTotalRows(response.length);
        } catch (error) {
            toast.error("Unable to fetch prefixes");
            console.error('Error fetching prefixes:', error);
        } finally {
            setLoading(false);
        }
    };

    const handlePageChange = page => {
        setCurrentPage(page);
    };

    const handlePerRowsChange = (newPerPage, page) => {
        setPerPage(newPerPage);
        setCurrentPage(page);
    };

    const toggleAddModal = () => {
        setShowAddModal(!showAddModal);
    };

    const toggleEditModal = () => {
        setShowEditModal(!showEditModal);
    };

    const confirmDelete = (rowId) => {
        setPrefixToDelete(rowId);
        setModalDelete(true); // Open delete modal
    };

    const handleDelete = async () => {
        if (prefixToDelete) {
            const updatedPrefixes = prefixes.filter(prefix => prefix._id !== prefixToDelete);
            setPrefixes(updatedPrefixes);

            try {
                await axios.delete(`${process.env.REACT_APP_BASE_URL}/auth/delete/prefix/${prefixToDelete}`);
                toast.success("Prefix deleted successfully");
            } catch (error) {
                console.error('Error deleting prefix:', error);
                toast.error("Unable to delete prefix");
            } finally {
                setModalDelete(false); // Close delete modal
                setPrefixToDelete(null);
            }
        }
    };

    const handleEdit = (row) => {
        setPrefixToEdit(row);
        setShowEditModal(true);
    };

    const DeleteButton = ({ row }) => (
        <Button
            variant="contained"
            color="error"
            onClick={() => confirmDelete(row._id)}
            style={{ height: '30px', margin: 'auto' }}
        >
            Delete
        </Button>
    );

    const EditButton = ({ row }) => (
        <Button
            variant="contained"
            color="primary"
            onClick={() => handleEdit(row)}
            style={{ height: '30px', margin: 'auto' }}
        >
            Edit
        </Button>
    );

    const columns = [
        {
            name: 'Prefix',
            selector: row => row.prefix,
            sortable: true,
        },
        {
            name: 'Sequence',
            selector: row => row.seq,
            sortable: true,
        },
        {
            name: 'isActive',
            selector: row => row.isActive ? 'Active' : 'Inactive',
            sortable: true,
        },
        {
            name: 'Actions',
            cell: row => (<><EditButton row={row} /><DeleteButton row={row} /></>),
            button: true,
            width: '200px',
            right: true,
        },
    ];

    const currentPageData = prefixes.slice(
        (currentPage - 1) * perPage,
        currentPage * perPage
    );

    document.title = "My Prefix | EHS Certificate";
    return (
        <div className="container mt-4" style={{ marginTop: "4rem", paddingTop: "4rem" }}>
            <div className="card">
                <div className="card-header d-flex justify-content-between align-items-center">
                    <h4>Providing Prefixes</h4>
                    <Button color="success" className="add-btn me-1" onClick={toggleAddModal}>
                        <i className="ri-add-line align-bottom me-1"></i>
                        Add Prefix
                    </Button>
                </div>
                <div className="card-body">
                    <div className="table-responsive">
                        <DataTable
                            columns={columns}
                            data={currentPageData}
                            progressPending={loading}
                            pagination
                            paginationServer
                            paginationTotalRows={totalRows}
                            paginationRowsPerPageOptions={[10, 50, 100]}
                            onChangeRowsPerPage={handlePerRowsChange}
                            onChangePage={handlePageChange}
                        />
                    </div>
                </div>
            </div>

            {/* Modal for deleting prefix */}
            <Modal
                isOpen={modalDelete}
                toggle={() => setModalDelete(false)}
                centered
                style={{ maxHeight: '50vh' }} // Decrease the height
            >
                <ModalHeader
                    className="bg-light p-3"
                    toggle={() => setModalDelete(false)}
                >
                    <span style={{ marginRight: "210px" }}>Remove Prefix</span>
                </ModalHeader>

                <ModalBody>
                    <div className="mt-2 text-center">
                        <lord-icon
                            src="https://cdn.lordicon.com/gsqxdxog.json"
                            trigger="loop"
                            colors="primary:#f7b84b,secondary:#f06548"
                            style={{ width: "100px", height: "100px" }}
                        ></lord-icon>
                        <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
                            <h4>Are you sure?</h4>
                            <p className="text-muted mx-4 mb-0">
                                Are you sure you want to remove this prefix?
                            </p>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <div className="hstack gap-2 justify-content-end">
                        <button
                            type="submit"
                            className="btn btn-danger"
                            onClick={handleDelete}
                        >
                            Remove
                        </button>
                        <button
                            type="button"
                            className="btn btn-outline-danger"
                            onClick={() => setModalDelete(false)}
                        >
                            Close
                        </button>
                    </div>
                </ModalFooter>
            </Modal>

            {/* Dialog for adding prefix */}
            <Modal isOpen={showAddModal} toggle={toggleAddModal} centered size="lg" style={{ height: '20vh' }}>
                <ModalHeader toggle={toggleAddModal}>Add Prefix</ModalHeader>
                <ModalBody>
                    <AddPrefix toggle={toggleAddModal} onPrefixAdded={fetchPrefixes} />
                </ModalBody>
            </Modal>

            {/* Dialog for editing prefix */}
            <Modal isOpen={showEditModal} toggle={toggleEditModal} centered size="lg" style={{ maxHeight: '50vh' }}>
                <ModalHeader toggle={toggleEditModal}>Edit Prefix</ModalHeader>
                <ModalBody>
                    <EditPrefix
                        toggle={toggleEditModal}
                        prefixData={prefixToEdit}
                        onPrefixUpdated={fetchPrefixes}
                    />
                </ModalBody>
            </Modal>

            <ToastContainer />
        </div>
    );
};

export default MyPrefix;
