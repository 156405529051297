import React, { useEffect, useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Form, FormGroup, Label, Input, FormFeedback } from 'reactstrap';
import axios from 'axios';
import { useDropzone } from 'react-dropzone';
import { useNavigate } from 'react-router-dom'; 
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const IssueCertiDirect = ({ companyId, companyCategory, agentId, isOpen, toggleModal }) => {
    const [formData, setFormData] = useState({
        certificateId: '',
        issueDate: '',
        expireDate: '',
        remarks: '',
        agent: {},
        isActive: true
    });

    const [isLifetime, setIsLifetime] = useState(false);
    const [file, setFile] = useState(null);
    const [loading, setLoading] = useState(false);
    const [options, setOptions] = useState([]);

    const navigate = useNavigate();

    const fetchCertificatesByCompanyCategoryAndAgent = async () => {
        try {
            console.log(companyCategory, "CTTT");
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/auth/list/certificatesByCompanyCategoryAndAgent`, {
                companyCategoryId: companyCategory,
                agentId: agentId
            });
            console.log('Response:', response); // Log the response data to check its structure

            if (Array.isArray(response.certificates)) {
                const fetchedCertificates = response.certificates.map(cert => ({
                    value: cert._id,
                    label: cert.certificateName
                }));
                setOptions(fetchedCertificates);
            } else {
                console.error('Invalid response data format:', response);
            }
        } catch (error) {
            console.error('Error fetching certificates:', error);
        }
    };

    useEffect(() => {
        fetchCertificatesByCompanyCategoryAndAgent();
    }, [companyCategory]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const formDataWithFile = new FormData();
            formDataWithFile.append('companyId', companyId);
            formDataWithFile.append('certificateId', formData.certificateId);
            formDataWithFile.append('issueDate', formData.issueDate);
            formDataWithFile.append('expireDate', formData.expireDate);
            formDataWithFile.append('remarks', formData.remarks);
            formDataWithFile.append('isActive', true);
            formDataWithFile.append('agent', agentId);
            formDataWithFile.append('certificateFile', file);

            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/auth/create/certificateForCompany`, formDataWithFile, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            setFormData({
                certificateId: '',
                issueDate: '',
                expireDate: '',
                remarks: '',
                isActive: true,
            });
            setFile(null);
            toast.success('Certificate Uploaded Successfully');
            console.log("Certificate Uploaded Successfully");
            navigate('/reports');
        } catch (error) {
            console.error('Error issuing certificate:', error);
            toast.error('Error Issuing certificate');
        } finally {
            setLoading(false);
        }
    };

    const [fileError, setFileError] = useState('');
    const { getRootProps, getInputProps } = useDropzone({
        accept: '.pdf, .jpg, .jpeg, .png',
        multiple: false,
        onDrop: (acceptedFiles) => {
            const selectedFile = acceptedFiles[0];
            if (selectedFile.size > 5242880) { // 5MB in bytes
              setFileError('File size should be a maximum of 5MB');
              setFile(null);
              toast.error('File size should be a maximum of 5MB');
            } else {
              setFile(selectedFile);
              setFileError('');
            }
        },
    });

    const cancel = () => {
        toggleModal();
        setFormData({
            certificateId: '',
            issueDate: '',
            expireDate: '',
            remarks: '',
            agent: {},
            isActive: true
        });
    };

    return (
        <Modal isOpen={isOpen} toggle={toggleModal}>
            <ModalHeader toggle={cancel}>Upload Certificate</ModalHeader>
            <ModalBody>
                <Form onSubmit={handleSubmit}>
                    <FormGroup>
                        <Label for="certificateId">Certificate</Label>
                        <Input
                            type="select"
                            name="certificateId"
                            id="certificateId"
                            value={formData.certificateId}
                            onChange={handleChange}
                            required
                        >
                            <option value="">Select Certificate</option>
                            {options.map((certificate) => (
                                <option key={certificate.value} value={certificate.value}>{certificate.label}</option>
                            ))}
                        </Input>
                    </FormGroup>
                    <FormGroup>
                        <Label for="issueDate">Issue Date</Label>
                        <Input
                            type="date"
                            name="issueDate"
                            id="issueDate"
                            value={formData.issueDate}
                            onChange={handleChange}
                            required
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label for="expireDate">Expiration Date</Label>
                        <Input
                            type="date"
                            name="expireDate"
                            id="expireDate"
                            value={!isLifetime ? formData.expireDate : null}
                            onChange={handleChange}
                            disabled={isLifetime}
                        />
                    </FormGroup>
                    <FormGroup check>
                        <Label check>
                            <Input
                                type="checkbox"
                                checked={isLifetime}
                                onChange={() => {
                                    if (!isLifetime) {
                                        setFormData({ ...formData, expireDate: null });
                                    }
                                    setIsLifetime(!isLifetime);
                                }}
                            />{' '}
                            Lifetime
                        </Label>
                    </FormGroup>
                    <FormGroup>
                        <Label for="remarks">Remarks</Label>
                        <Input
                            type="textarea"
                            name="remarks"
                            id="remarks"
                            value={formData.remarks}
                            onChange={handleChange}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label for="certificateFile">Certificate File (max size 5MB)</Label>
                        <div {...getRootProps()} style={{ cursor: 'pointer', padding: '10px', border: '1px dashed #ccc' }}>
                            <input {...getInputProps()} />
                            {file ? (
                                <p>{file.name}</p>
                            ) : (
                                <p>Drag 'n' drop file here, or click to select file</p>
                            )}
                        </div>
                    </FormGroup>
                    {fileError && <FormFeedback style={{ display: 'block' }}>{fileError}</FormFeedback>}
                    <ModalFooter>
                        <Button color="primary" type="submit" disabled={!formData.certificateId || loading}>
                            {loading ? 'Uploading...' : 'Upload Certificate'}
                        </Button>
                    </ModalFooter>
                    <ToastContainer />
                </Form>
            </ModalBody>
        </Modal>
    );
};

export default IssueCertiDirect;
