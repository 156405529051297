import React, { useState } from "react";
import { Button, Card, CardBody, Col, Container, Input, Label, Row } from "reactstrap";
import { Alert } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
// import logo from "../../assets/images/logo/RC-logo-png.png";
import logo from "../../assets/images/certificate/logo.jpg"
import SearchableDropdown from "./SearchableDropdown"; // Adjust the path as needed

const initialState = {
    name: "",
    email: "",
    password: "",
    mobileNumber: "",
    providingCertificates: [], // Initial empty array for selected certificates
    isActive: true,
    gmailId: "",
    gmailPassword: "",
};

const AgentRegister = () => {
    const [values, setValues] = useState(initialState);
    const [formErrors, setFormErrors] = useState({});
    const [isSubmit, setIsSubmit] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [isLogin, setIsLogin] = useState(false);

    const navigate = useNavigate();

    const handleChange = (e) => {
        const { name, value } = e.target;
        setValues({ ...values, [name]: value });
    };

    // Handle updates to selectedCertificates from SearchableDropdown
    const handleSelectedCertificates = (selectedCertificates) => {
        setValues({ ...values, providingCertificates: selectedCertificates });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmit(true);
        const errors = validate(values);
        setFormErrors(errors);

        if (Object.keys(errors).length === 0) {
            try {
                const res = await axios.post(`${process.env.REACT_APP_BASE_URL}/create/agent`, values);
                console.log('Response from createAgent API:', res);

                // Log the entire response object to understand its structure
                // console.log('Response data:', res.data);

                // Check if the response contains the expected data structure
                try{
                    toast.success("Registration successful!");
                    const agentData = res.agent;
                    const { _id } = agentData;
                    // localStorage.setItem('agentId', _id);
                    // console.log('Agent data stored in local storage');
                    // navigate("/login");
                    // toast.success("Reset link sent to your email");
                    setTimeout(() => {
                      navigate("/login");
                    }, 2000); // 2 seconds delay
                } catch (error) {
                    toast.error("Registration failed!");
                }
            } catch (error) {
                // Log any errors that occur during registration
                console.error('Error during registration:', error);
                toast.error("Registration failed!");
            }
        } else {
            // toast.error("Form validation failed!");
        }
    };

    const validate = (values) => {
        const errors = {};
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        if (!values.email) {
            errors.email = "Email is required!";
        } else if (!regex.test(values.email)) {
            errors.email = "Invalid Email address!";
        }

        if (!values.password) {
            errors.password = "Password is required!";
        }

        if (!values.name) {
            errors.name = "Agent Name is required!";
        }

        if (!values.mobileNumber) {
            errors.mobileNumber = "Mobile Number is required!";
        } else if (!/^\d{10}$/.test(values.mobileNumber)) {
            errors.mobileNumber = "Invalid Mobile Number!";
        }

        if (values.providingCertificates.length === 0) {
            errors.providingCertificates = "Select at least one certificate!";
        }

        return errors;
    };

    const validClassEmail = formErrors.email && isSubmit ? "form-control is-invalid" : "form-control";
    const validClassPassword = formErrors.password && isSubmit ? "form-control is-invalid" : "form-control pe-5";
    const validClassAgentName = formErrors.name && isSubmit ? "form-control is-invalid" : "form-control";
    const validClassMobileNumber = formErrors.mobileNumber && isSubmit ? "form-control is-invalid" : "form-control";

    document.title = "Register | EHS ";

    return (
        <React.Fragment>
            <ToastContainer />
            <div className="auth-page-content">
                <Container>
                    <Row>
                        <Col lg={12}>
                            <div className="text-center mt-sm-5 mb-4 text-white-50"></div>
                        </Col>
                    </Row>

                    <Row className="justify-content-center">
                        <Col md={8} lg={6} xl={5}>
                            <Card style={{ marginTop: '5%' }}>
                                <CardBody className="p-4">
                                    <div className="text-center mt-2">
                                        <div
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                            }}
                                        >
                                            <div className="site-logo">
                                                <Link to="/">
                                                    <img
                                                        style={{ display: 'flex', alignItems: 'center' }}
                                                        src={logo}
                                                        height={'70px'}
                                                        width={'80px'}
                                                        alt="Project Name"
                                                    />
                                                </Link>
                                            </div>
                                        </div>
                                        <h5 className="text-primary mt-2">Authority Registration</h5>
                                        <p className="text-muted">Register to start.</p>
                                    </div>
                                    {/* {Object.keys(formErrors).length > 0 && (
                                        <Alert color="danger">
                                            {Object.values(formErrors).map((error, index) => (
                                                <p key={index}>{error}</p>
                                            ))}
                                        </Alert>
                                    )} */}
                                    <div className="p-2 mt-4">
                                        <form className="register-form" onSubmit={handleSubmit}>
                                            <div className="mb-3">
                                                <Label htmlFor="name" className="form-label">
                                                    Name <span className="text-danger">*</span>
                                                </Label>
                                                <Input
                                                    name="name"
                                                    className={validClassAgentName}
                                                    placeholder="Enter agent name"
                                                    type="text"
                                                    onChange={handleChange}
                                                    value={values.name}
                                                />
                                                {isSubmit && <p className="text-danger">{formErrors.name}</p>}
                                            </div>
                                            <div className="mb-3">
                                                <Label htmlFor="email" className="form-label">
                                                    Email <span className="text-danger">*</span>
                                                </Label>
                                                <Input
                                                    name="email"
                                                    className={validClassEmail}
                                                    placeholder="Enter email"
                                                    type="email"
                                                    onChange={handleChange}
                                                    value={values.email}
                                                />
                                                {isSubmit && <p className="text-danger">{formErrors.email}</p>}
                                            </div>
                                            <div className="mb-3">
                                                <Label htmlFor="password-input" className="form-label">
                                                    Password <span className="text-danger">*</span>
                                                </Label>
                                                <div className="position-relative auth-pass-inputgroup mb-3">
                                                    <Input
                                                        name="password"
                                                        value={values.password}
                                                        type={showPassword ? 'text' : 'password'}
                                                        className={validClassPassword}
                                                        placeholder="Enter Password"
                                                        onChange={handleChange}
                                                    />
                                                    {isSubmit && <p className="text-danger">{formErrors.password}</p>}
                                                    <button
                                                        className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted"
                                                        type="button"
                                                        id="password-addon"
                                                        onClick={() => setShowPassword(!showPassword)}
                                                    >
                                                        {showPassword ? (
                                                            <i className="ri-eye-off-fill align-middle"></i>
                                                        ) : (
                                                            <i className="ri-eye-fill align-middle"></i>
                                                        )}
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="mb-3">
                                                <Label htmlFor="mobileNumber" className="form-label">
                                                    Mobile Number <span className="text-danger">*</span>
                                                </Label>
                                                <Input
                                                    name="mobileNumber"
                                                    className={validClassMobileNumber}
                                                    placeholder="Enter mobile number"
                                                    type="text"
                                                    onChange={handleChange}
                                                    value={values.mobileNumber}
                                                />
                                                {isSubmit && <p className="text-danger">{formErrors.mobileNumber}</p>}
                                            </div>
                                            {/* <div className="mb-3">
                                                <Label htmlFor="providingCertificates" className="form-label">
                                                    Mail for reminders
                                                </Label>
                                                <Input
                                                    name="gmailId"
                                                    className="form-control"
                                                    placeholder="Enter email for reminders"
                                                    type="email"
                                                    onChange={handleChange}
                                                    value={values.gmailId}
                                                />
                                            </div>
                                            <div className="mb-3">
                                                <Label htmlFor="providingCertificates" className="form-label">
                                                    Mail ID password
                                                </Label>
                                                <Input
                                                    name="gmailPassword"
                                                    className="form-control"
                                                    placeholder="Enter mail ID password"
                                                    type="password"
                                                    onChange={handleChange}
                                                    value={values.gmailPassword}
                                                />
                                            </div> */}
                                            <div className="mb-3">
                                                {/* <Label htmlFor="providingCertificates" className="form-label">
                                                    Certificates <span className="text-danger">*</span>
                                                </Label> */}
                                                <SearchableDropdown
                                                    selectedCertificates={values.providingCertificates}
                                                    setSelectedCertificates={handleSelectedCertificates}
                                                />
                                                {isSubmit && formErrors.providingCertificates && (
                                                    <p className="text-danger">{formErrors.providingCertificates}</p>
                                                )}
                                            </div>
                                            <input
                                                type="hidden"
                                                name="isActive"
                                                value={values.isActive}
                                            />
                                            <div className="mt-4">
                                                <Button
                                                    color="success"
                                                    className="btn btn-success w-100"
                                                    type="submit"
                                                >
                                                    Register Agent
                                                </Button>
                                            </div>
                                            <div className="mt-4 text-center">
                                                <p className="mb-0">
                                                    Already have an account?
                                                    <Link to="/login" className="text-primary ms-1">
                                                        <Button
                                                            color="link"
                                                            className="text-primary"
                                                            onClick={() => navigate("/agent-login")}
                                                        >
                                                            Log In
                                                        </Button>
                                                    </Link>
                                                </p>
                                            </div>
                                        </form>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default AgentRegister;
