import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast, ToastContainer } from "react-toastify";
import DataTable from 'react-data-table-component';
import { Spinner, Col, Row, Container } from 'reactstrap';
import { Form, FormGroup, Label, Input } from 'reactstrap';
import { createStore } from "polotno/model/store";
import { useNavigate } from 'react-router-dom';
import { saveAs } from 'file-saver';
import {Button} from '@mui/material'
import AddClient from './MyClientModals/AddClient';
import IssueCertiDirect from './issueDirectCertificate';


const MyClients = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [categoryMap, setCategoryMap] = useState({});
    const [showForm, setShowForm] = useState(false);
    const [currentCompany, setCurrentCompany] = useState({});
    const [certificateOptions, setCertificateOptions] = useState([]);

    const agentId = localStorage.getItem('agentId')
    const [companyCategory, setCompanyCategory] = useState('')
    const [companyId, setCompanyId] = useState('')
    const [query, setQuery] = useState('')

    const getCertificatesByAgentIdandCompanyCategoryId = async () => {
        const agentId = localStorage.getItem('agentId');
        try {
            console.log("Company Category:", currentCompany.companyCategoryId);
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/auth/list/certificatesByCompanyCategoryAndAgent/`, {
                agentId: agentId,
                companyCategoryId: currentCompany.companyCategoryId[0]._id,
            });
            setCertificateOptions(response.certificates); 
            console.log("Certificates:", response.certificates);
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        fetchCategoriesAndData(1, perPage);
    }, [query]);

    useEffect(() => {
        getCertificatesByAgentIdandCompanyCategoryId();
    }, [showForm]);

    useEffect(() => {
        fetchCategoriesAndData(1, perPage);
    }, [perPage]);
    const [isOpen, setIsOpen] = useState(false);
    useEffect(() => {
        // fetchUsers(1); // fetch page 1 of users
    }, [isOpen]);

    const toggleModalIssueCertificate = () => setIsOpen(!isOpen);

    const fetchCategoriesAndData = async (page, perPage) => {
        setLoading(true);
        try {
            const categoriesResponse = await axios.get(`${process.env.REACT_APP_BASE_URL}/list/categories`);
            
            if (!Array.isArray(categoriesResponse)) {
                throw new Error("Categories data is not an array");
            }
    
            const categories = categoriesResponse.reduce((acc, category) => {
                acc[category._id] = category.categoryName;
                return acc;
            }, {});
            setCategoryMap(categories);
            const agentId = localStorage.getItem('agentId');

            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/auth/list-by-params/companiesByAgent/${agentId}`, {
                match: query,
            });
   
            console.log("Agent:", response);
            const companies = response.data[0].companies.map(company => ({
                companyName: company.companyName || 'No Company Name',
                companyCategory: company.companyCategory.categoryName || 'Category Not Found',
                email: company.email || 'No Email',
                mobileNumber: company.mobileNumber || 'No Mobile Number',
                companyCategoryId: company.companyCategory,
                _id: company._id,
                companyCategoryId:company.companyCategory._id,
                remarks: company.remarks || 'No Remarks',
                companyCertificates: company.certificates || [], 
            }));
    
            setData(companies);
            // console.log(companies.length);
            setTotalRows(companies.length);
            // toast.success("Fetched Company Details");
        } catch (error) {
            // toast.error("Unable to fetch companies");
            console.error("Error fetching data: ", error);
        } finally {
            setLoading(false);
        }
    };
    const handlePageChange = (page) => {
        fetchCategoriesAndData(page, perPage);
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        setPerPage(newPerPage);
        fetchCategoriesAndData(page, newPerPage);
    };

    // const [companyID, setCompanyID] = useState('');

    const FetchCertificateNumber = async () => {
        const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/auth/create/certificateNumber`, {
            prefixId: selectedPrefix,
        });

        return response.data.certificateNumber;
    };
    const navigate = useNavigate();

    const createCertificateByAgent = async (data) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/auth/create/certificateByAgentByTemplate/${templateId}`, data);
            console.log("Certificate Response:", response);
            // toast.success("Certificate created successfully");

            return response;
            
        } catch (error) {
            console.error("Error creating certificate:", error);
            toast.error("Error creating certificate");
        }
    };

    const handleSubmit = async (e) => {
        
        e.preventDefault();
        const certificateNumber = await FetchCertificateNumber();
        console.log("Certificate Number:", certificateNumber);

        formValues.$Certificate_Number = certificateNumber;
        // log form data
        // console.log("Data:", data);
        // console.log("Current Company:", currentCompany);

        for(let i = 0; i < data.length; i++){
            // console.log("Company:", data[i]);
            if(data[i].email === currentCompany.email){
                const payload = {
                    company: data[i]._id,
                    agent: localStorage.getItem('agentId'),
                    certificate: selectedCertificate,
                    data: formValues,
                    isActive: true,
                    certificateNo: certificateNumber
                }
                const certificateResponse = await createCertificateByAgent(payload);
                navigate('/confirm-certificate',{state:{certificateResponse:certificateResponse}});
                setShowForm(false); 
                break;        
            }
        }
    };
// Certificate I'd ,
// Agent I'd --
// True --
// Company I'd --
// Data ma olo object --
// Certificateno  ma response of that create certificate number request
    const handleCancel = () => {
        setShowForm(false);
    };

    const [selectedCertificate, setSelectedCertificate] = useState('');
    const [formFields, setFormFields] = useState([]);

    const handleSelectChange = (event) => {
        setSelectedCertificate(event.target.value);
        // console.log("Selected Certificate:", event.target.value);
    };

    const [isTemplatePresent, setIsTemplatePresent] = useState(false);
    const [templateId, setTemplateId] = useState('');


    const getTemplateByCertificateIdAndAgentId = async (certificateId) => {
            const agentId = localStorage.getItem('agentId');
        try{
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/auth/get/templateByCertificateIdAndAgentId/`, {
                agentId: agentId,
                certId: certificateId
            });
            if(response.template) {
                setIsTemplatePresent(true)
                setTemplateId(response.template._id)
            }
            else{
                toast.error("Error fetching template");
                setIsTemplatePresent(false)
            }
        }
        catch (error) {
            toast.error("Select Certificate with an existing template");
            setIsTemplatePresent(false)
        }
    }
    useEffect(() => {
        if(selectedCertificate) getTemplateByCertificateIdAndAgentId(selectedCertificate);
    }, [selectedCertificate]);


    
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [editingCertificate, setEditingCertificate] = useState(null);
    const [showModal, setShowModal] = useState(false);
    // Remove Certificate Modal
    const [removeModalOpen, setRemoveModalOpen] = useState(false);
    const [certificateToRemove, setCertificateToRemove] = useState(null);
 
    const toggleModal = () => {
        setShowModal(!showModal);
    };

    const handleEditModalClose = () => {
        setEditModalOpen(false);
        setEditingCertificate(null);
        // fetchData(1, perPage); // Fetch certificates again to get updated data
        fetchCategoriesAndData(1, perPage);
    };

    const handleRemoveConfirm = async () => {
        console.log("Removing certificate: ", certificateToRemove)
        if (certificateToRemove) {
            try {
                await axios.delete(`h${process.env.REACT_APP_BASE_URL}/auth/delete/companyCertificate/${certificateToRemove._id}`);
                // fetchData(1, perPage); // Refresh the data after deletion
                fetchCategoriesAndData(1, perPage);
                setRemoveModalOpen(false);
                setCertificateToRemove(null);
            } catch (error) {
                console.error("Error removing certificate: ", error);
                // toast.error('Error deleting Certificate');
            }
        }
    };

    useEffect(() => {
        if (!showModal){
            fetchCategoriesAndData(1, perPage);
        }
    }, [showModal]);
    

    const listFieldsByParams = async () => {
        try {
            const _id = localStorage.getItem('agentId');
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/auth/list-by-params/field/${_id}`, {
                skip: 0,
                per_page: perPage,
                sorton: 'createdAt',
                sortdir: 'desc',
                match: "",
                certId: selectedCertificate || "",
                isActive: true
            });
            // console.log("Fields:", response.data);
            setFormFields(response.data);
        } catch (error) {
            console.error('Error fetching fields:', error);
            toast.error("Unable to fetch fields");
        } finally {
            setLoading(false);
        }
    }
    useEffect(() => {
        {selectedCertificate && listFieldsByParams()}
        // console.log("length: ", formFields?.length);
    }, [selectedCertificate]);


    const [formValues, setFormValues] = useState({});
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        // console.log("Name:", name, "Value:", value);
        if(name === 'prefix') setSelectedPrefix(value);
        else setFormValues(prevValues => ({ ...prevValues, [name]: value }));
    };

    const [prefixOptions, setPrefixOptions] = useState([]);
    const [selectedPrefix, setSelectedPrefix] = useState('');
    const getAllPrefix = async () => {
        try {
            const _id = localStorage.getItem('agentId');
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/auth/list/prefix/${_id}`);
            setPrefixOptions(response);
            // console.log("Prefix Options:", response);
        } catch (error) {
            console.error('Error fetching prefix:', error);
            toast.error("Unable to fetch prefix");
        }
    };
    useEffect(() => {
        (selectedCertificate && getAllPrefix())
    }, [selectedCertificate])


    const handleDownloadExcel = async (compId) => {
        try {
        //   let skip = (pageNo - 1) * perPage;
        //   if (skip < 0) {
        //     skip = 0;
        //   }
      
          const response = await axios.post(
            `${process.env.REACT_APP_BASE_URL}/auth/sheet/certificatesByAgentAndCompany`,
            {
                companyId: compId,
                agentId: agentId,
            //   skip: skip,
            //   per_page: perPage,
            //   sorton: column,
            //   sortdir: sortDirection,
            //   match: query,
            //   isVerified: filterV,
            //   isActive: filter,
            },
            {
              responseType: 'blob', // Important to handle binary data
            }
          );
      
          console.log("excel response", response);
      
          // Create a new Blob object using the response data of the file
          const blob = new Blob([response], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          });
      
          // Save the file using FileSaver
          saveAs(blob, 'report.xlsx');
        } catch (error) {
          console.error("Error downloading the file:", error);
            toast.error("Error downloading the file");
        }
      };
      

    const columns = [
        {
            name: 'Client Name',
            cell: row => row.companyName,
            sortable: true,
            maxWidth: '200px',
        },
        {
            name: 'Client Category',
            selector: row => row.companyCategory,
            sortable: true,
            maxWidth: '150px',
        },
        {
            name: 'Current Certificates',
            cell: row => (
                <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                {row.companyCertificates.map(cert => (
                    <li key={cert.certificateDetails.certificateName}>
                        {cert.certificateDetails.certificateName}
                    </li>
                ))}
            </ul>
            ),
            minWidth: '300px',
        },
        {
            name: 'Email',
            cell: row => row.email,
            sortable: true,
            maxWidth: '200px',
        },
        {
            name: 'Mobile Number',
            selector: row => row.mobileNumber,
            sortable: true,
            maxWidth: '150px',
        },
        {
            name: 'Actions',
            // cell: row =><div> <button className="btn btn-primary"  onClick={() => handleGenerateCertificate(row)}>Generate Certificate</button> </div>,
            cell: row =><div className='d-flex flex-wrap justify-content-between'>  
            <button className="btn btn-success mt-2 mb-2 me-1"
                    // style={{ minWidth: '150px', fontSize: '0.875rem' }}
                    onClick={() => handleDownloadExcel(row._id)}
                >
                <i className="ri-download-2-fill align-bottom me-1"></i>
                Certificates
            </button>
        
            <button className="btn btn-primary mt-2 mb-2" 
                    // style={{ minWidth: '150px', fontSize: '0.875rem' }}
                    onClick={() => {
                        setCompanyCategory(row.companyCategoryId);
                        setCompanyId(row._id);
                        toggleModalIssueCertificate();
                    }}
                >
                Upload Certificate
            </button> 
        </div>,
        minWidth: '350px',
        }
    ];

    document.title = " Clients | EHS Certificate ";

    return (
        <div className="page-content">
            <Container fluid>
                <Row>
                    <Col xs={12}>
                        {showForm ? (
                            <div className="card">
                                <div className="card-header d-flex justify-content-between align-items-center">
                                    <h4>Generate Certificate</h4>
                                </div>
                                <div className="card-body">
                                <Label for="certificateSelect">Select Certificate</Label>
                                            <Input
                                                type="select"
                                                name="certificateSelect"
                                                id="certificateSelect"
                                                value={selectedCertificate}
                                                onChange={handleSelectChange}
                                            >
                                                <option value="" disabled>Select a certificate</option>
                                                {certificateOptions.map(option => (
                                                    <option key={option._id} value={option._id}>
                                                        {option.certificateName}
                                                    </option>
                                                ))}
                                            </Input>
{isTemplatePresent ? (
                                    <Form onSubmit={(e) => handleSubmit(e, selectedCertificate)} style={{marginTop: "1.5rem"}}>
                                        <FormGroup>
                                            {prefixOptions && prefixOptions.length > 0 && (
                                                    <FormGroup style={{ display: 'flex', alignItems: 'center', padding: '0 2rem'}}>
                                                    <Label for="prefix" style={{ flex: '1', marginRight: '1rem' }}>Select Prefix for Certificate:</Label>
                                                    <Input
                                                        type="select"
                                                        name="prefix"
                                                        id="prefix"
                                                        value={selectedPrefix}
                                                        required
                                                        onChange={handleInputChange}
                                                        style={{ flex: '2' }}
                                                    >
                                                        <option value="" disabled>Select a prefix</option>
                                                        {prefixOptions.map(option => (
                                                            <option key={option._id} value={option._id}>
                                                                {option.prefix}
                                                            </option>
                                                        ))}
                                                    </Input>
                                                </FormGroup>
                                                // ))
                                            )}
                                            {formFields && formFields.length > 0 && (
                                                formFields.map(field => (
                                                    <FormGroup key={field._id} style={{ display: 'flex', alignItems: 'center', padding: '0 2rem'}}>
                                                        <Label for={field.key} style={{ flex: '1', marginRight: '1rem' }}>{field.fieldName}</Label>
                                                        <Input
                                                            type={field.fieldType}
                                                            name={field.key}
                                                            id={field.key}
                                                            // required
                                                            placeholder={field.key}
                                                            onChange={handleInputChange}
                                                            style={{ flex: '2' }}
                                                        />
                                                    </FormGroup>
                                                ))
                                            )}
                                        </FormGroup>
                                        {formFields && formFields.length > 0 && (
                                        <>
                                            <Button color="primary" type="submit" style={{marginRight: "2rem"}}>Submit</Button>
                                            <Button color="secondary" type="button" onClick={handleCancel} className="ml-2">Cancel</Button>
                                        </>
                                        )}
                                    </Form>
                                    ): (
                                    <div className="text-center" style={{margin: "3rem"}}>
                                        <h4>Select Certificate with a template</h4>
                                    </div>
                                )}
                                </div>
                            </div>
                        ) : (
                            <>
                                <ToastContainer />
                                <div className="card">
                                    <div className="card-header d-flex justify-content-between align-items-center">
                                        <h4>Clients</h4>
                                        <div className='d-flex'>
                                            {/* search */}
                                            <div
                                                className="search-box ms-2"
                                            >
                                                <input
                                                    className="form-control search"
                                                    placeholder="Search..."
                                                    onChange={(e) => setQuery(e.target.value)}
                                                />
                                                <i className="ri-search-line search-icon "></i>
                                            </div>
                                        <Button color="success" className="add-btn me-1" onClick={toggleModal}>
                                            <i className="ri-add-line align-bottom me-1"></i>
                                            Add Client
                                        </Button>
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <div className="table-responsive">
                                            <DataTable
                                                columns={columns}
                                                data={data}
                                                progressPending={loading}
                                                progressComponent={<Spinner color="primary" />}
                                                pagination
                                                // paginationServer
                                                // paginationTotalRows={totalRows}
                                                paginationRowsPerPageOptions={[10, 50, 100]}
                                                // onChangeRowsPerPage={handlePerRowsChange}
                                                // onChangePage={handlePageChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                                {/* Add Certificate Modal */}
                                <AddClient
                                    currentCompanies={data}
                                    isOpen={showModal}
                                    toggleModal={toggleModal}
                                />
                            </>
                        )}
                    </Col>
                </Row>
                
            <IssueCertiDirect

                agentId={agentId}
                companyCategory={companyCategory}
                companyId={companyId}
                isOpen={isOpen}
                toggleModal={toggleModalIssueCertificate}
            />
            <ToastContainer />
            </Container>
        </div>
    );
};

export default MyClients;
