import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Button,
  Card,
  Col,
  Container,
  Row,
  Form,
  Label,
  Input,
  FormFeedback,
} from "reactstrap";

// import AuthSlider from '../authCarousel';

//formik
import { useFormik } from "formik";
import * as Yup from "yup";
import { useLocation } from "react-router-dom";
const CoverPasswCreate = () => {
  document.title = "Create New Password | EHS";

  const [passwordShow, setPasswordShow] = useState(false);
  const [confrimPasswordShow, setConfrimPasswordShow] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get("token");

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      password: "",
      confrim_password: "",
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .min(6, "Password must be at least 6 characters")
        .required("Password is required"),
      confrim_password: Yup.string()
        .when("password", {
          is: (val) => (val && val.length > 0 ? true : false),
          then: Yup.string().oneOf(
            [Yup.ref("password")],
            "Both password need to be the same"
          ),
        })
        .required("Confirm Password Required"),
    }),
    onSubmit: async (values) => {
      try {
        // Send a request to the server to initiate the forgot password process
        const response = await fetch(
          `${
            process.env.REACT_APP_BASE_URL
          }/auth/reset-password/agent?token=${encodeURIComponent(token)}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ newPassword: values.password }),
          }
        );
        const data = await response.json();
        navigate("/login");
      } catch (error) {
        console.error("Error:", error);
      }
    },
  });
  return (
    <React.Fragment>
      <div className=" py-5 d-flex justify-content-center align-items-center min-vh-100">
        <div className=""></div>
        <div className="auth-page-content overflow-hidden pt-lg-5">
          <Container>
            <Row>
              <Col lg={12}>
                <Card className="overflow-hidden">
                <Row className="justify-content-center g-0">
    <Col lg={12} className="d-flex justify-content-center" style={{ minHeight: '50vh' }}>
        <div className="p-lg-5 p-4">
            <h5 className="text-primary text-center">Create new password</h5>
            <p className="text-muted text-center">
                Your new password must be different from previous used password.
            </p>

                        <div className="p-2">
                          <Form
                            onSubmit={validation.handleSubmit}
                            action="/auth-signin-basic"
                          >
                            <div className="mb-3">
                              <Label
                                className="form-label"
                                htmlFor="password-input"
                              >
                                Password
                              </Label>
                              <div className="position-relative auth-pass-inputgroup">
                                <Input
                                  type={passwordShow ? "text" : "password"}
                                  className="form-control pe-5 password-input"
                                  placeholder="Enter password"
                                  id="password-input"
                                  name="password"
                                  value={validation.values.password}
                                  onBlur={validation.handleBlur}
                                  onChange={validation.handleChange}
                                  invalid={
                                    validation.errors.password &&
                                    validation.touched.password
                                      ? true
                                      : false
                                  }
                                />
                                {validation.errors.password &&
                                validation.touched.password ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.password}
                                  </FormFeedback>
                                ) : null}
                                <Button
                                  color="link"
                                  onClick={() => setPasswordShow(!passwordShow)}
                                  className="position-absolute end-0 top-0 text-decoration-none text-muted password-addon"
                                  id="password-addon"
                                >
                                  <i className="ri-eye-fill align-middle"></i>
                                </Button>
                              </div>
                              <div id="passwordInput" className="form-text">
                                Must be at least 6 characters.
                              </div>
                            </div>

                            <div className="mb-3">
                              <Label
                                className="form-label"
                                htmlFor="confirm-password-input"
                              >
                                Confirm Password
                              </Label>
                              <div className="position-relative auth-pass-inputgroup mb-3">
                                <Input
                                  type={
                                    confrimPasswordShow ? "text" : "password"
                                  }
                                  className="form-control pe-5 password-input"
                                  placeholder="Confirm password"
                                  id="confirm-password-input"
                                  name="confrim_password"
                                  value={validation.values.confrim_password}
                                  onBlur={validation.handleBlur}
                                  onChange={validation.handleChange}
                                  invalid={
                                    validation.errors.confrim_password &&
                                    validation.touched.confrim_password
                                      ? true
                                      : false
                                  }
                                />
                                {validation.errors.confrim_password &&
                                validation.touched.confrim_password ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.confrim_password}
                                  </FormFeedback>
                                ) : null}
                                <Button
                                  color="link"
                                  onClick={() =>
                                    setConfrimPasswordShow(!confrimPasswordShow)
                                  }
                                  className="position-absolute end-0 top-0 text-decoration-none text-muted password-addon"
                                >
                                  <i className="ri-eye-fill align-middle"></i>
                                </Button>
                              </div>
                            </div>

                            <div
                              id="password-contain"
                              className="p-3 bg-light mb-2 rounded"
                            >
                              <h5 className="fs-13">Password must contain:</h5>
                              <p
                                id="pass-length"
                                className="invalid fs-12 mb-2"
                              >
                                Minimum <b>8 characters</b>
                              </p>
                              <p id="pass-lower" className="invalid fs-12 mb-2">
                                At <b>lowercase</b> letter (a-z)
                              </p>
                              <p id="pass-upper" className="invalid fs-12 mb-2">
                                At least <b>uppercase</b> letter (A-Z)
                              </p>
                              <p
                                id="pass-number"
                                className="invalid fs-12 mb-0"
                              >
                                A least <b>number</b> (0-9)
                              </p>
                            </div>

                            <div className="mt-4">
                              <Button
                                color="success"
                                className="w-100"
                                type="submit"
                              >
                                Reset Password
                              </Button>
                            </div>
                          </Form>
                        </div>

                        <div className="mt-5 text-center">
                          <p className="mb-0">
                            Wait, I remember my password...{" "}
                            <Link
                              to="/login"
                              className="fw-semibold text-primary text-decoration-underline"
                            >
                              {" "}
                              Click here{" "}
                            </Link>{" "}
                          </p>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </React.Fragment>
  );
};

export default CoverPasswCreate;
