import React, { useEffect, useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Form, FormGroup, Label, Input, FormFeedback } from 'reactstrap';
import axios from 'axios';
import { useDropzone } from 'react-dropzone';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom'; 
import { format, isValid } from 'date-fns';

const MyReportsEditModal = ({ isOpen, certificateId, toggleModal, certiData, agentId }) => {
    const [formData, setFormData] = useState({
        isActive: true,
        companyId: certiData.companyId,
        issueDate: certiData.issueDate,
        expireDate: certiData.expireDate,
        remarks: certiData.remarks,
        agent: agentId,
    });

    const [isLifetime, setIsLifetime] = useState(false);
    const [file, setFile] = useState(null);
    const [fileError, setFileError] = useState('');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const isLifetimeCheck = certiData.expireDate === '1970-01-01T00:00:00.000Z' || certiData.expireDate === null;
        setIsLifetime(isLifetimeCheck);
        setFormData({
            isActive: true,
            companyId: certiData.companyId,
            issueDate: formatDate(certiData.issueDate),
            expireDate: isLifetimeCheck ? '' : formatDate(certiData.expireDate),
            remarks: certiData.remarks,
            agent: agentId,
        });
    }, [certiData, agentId]);

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        if (isValid(date)) {
            return format(date, 'yyyy-MM-dd');
        }
        return ''; // Return an empty string or handle the invalid date case as needed
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const formDataWithFile = new FormData();
            formDataWithFile.append('issueDate', formData.issueDate);
            formDataWithFile.append('expireDate', isLifetime ? '1970-01-01T00:00:00.000Z' : formData.expireDate);
            formDataWithFile.append('remarks', formData.remarks);
            formDataWithFile.append('isActive', true);
            formDataWithFile.append('agent', agentId);
            formDataWithFile.append('certificateFile', file);
            formDataWithFile.append('companyId', formData.companyId);

            await axios.put(`${process.env.REACT_APP_BASE_URL}/auth/update/certificateForCompany/${certificateId}`, formDataWithFile, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            setFormData({
                isActive: true,
                companyId: '',
                issueDate: '',
                expireDate: '',
                remarks: '',
                agent: '',
            });

            setFile(null);
            navigate('/reports');
        } catch (error) {
            console.error('Error issuing certificate:', error);
            toast.error('Error Issuing certificate');
        } finally {
            setLoading(false);
            toggleModal(); // Close the modal after issuance
        }
    };

    const { getRootProps, getInputProps } = useDropzone({
        accept: '.pdf, .jpg, .jpeg, .png',
        multiple: false,
        onDrop: (acceptedFiles) => {
            const selectedFile = acceptedFiles[0];
            if (selectedFile.size > 5242880) { // 5MB in bytes
              setFileError('File size should be a maximum of 5MB');
              setFile(null);
              toast.error('File size should be a maximum of 5MB');
            } else {
              setFile(selectedFile);
              setFileError('');
            }
        },
    });

    return (
        <Modal isOpen={isOpen} toggle={toggleModal} backdrop={true}>
            <ModalHeader toggle={toggleModal}>Update Certificate</ModalHeader>
            <ModalBody>
                <Form onSubmit={handleSubmit}>
                    <FormGroup>
                        <Label for="issueDate">Issue Date</Label>
                        <Input
                            type="date"
                            name="issueDate"
                            id="issueDate"
                            value={formData.issueDate}
                            onChange={handleChange}
                            required
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label for="expireDate">Expiration Date</Label>
                        <Input
                            type="date"
                            name="expireDate"
                            id="expireDate"
                            value={!isLifetime ? formData.expireDate : ''}
                            onChange={handleChange}
                            disabled={isLifetime}
                        />
                    </FormGroup>
                    <FormGroup check>
                        <Label check>
                            <Input
                                type="checkbox"
                                checked={isLifetime}
                                onChange={() => {
                                    setIsLifetime(!isLifetime);
                                    if (!isLifetime) {
                                        setFormData({ ...formData, expireDate: '' });
                                    }
                                }}
                            />{' '}
                            Lifetime
                        </Label>
                    </FormGroup>
                    <FormGroup>
                        <Label for="remarks">Remarks</Label>
                        <Input
                            type="textarea"
                            name="remarks"
                            id="remarks"
                            value={formData.remarks}
                            onChange={handleChange}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label for="certificateFile">Change Certificate File(max size 5MB)</Label>
                        <div {...getRootProps()} style={{ cursor: 'pointer', padding: '10px', border: '1px dashed #ccc' }}>
                            <input {...getInputProps()} />
                            {file ? (
                                <p>{file.name}</p>
                            ) : (
                                <p>Drag 'n' drop file here, or click to select file</p>
                            )}
                        </div>
                    </FormGroup>
                    {fileError && <FormFeedback style={{ display: 'block' }}>{fileError}</FormFeedback>}
                    <ModalFooter>
                        <Button color="primary" type="submit" disabled={!certificateId || loading}>
                            {loading ? 'Update...' : 'Update Certificate'}
                        </Button>
                    </ModalFooter>
                </Form>
            </ModalBody>
        </Modal>
    );
};

export default MyReportsEditModal;
