import React, { useContext, useEffect, useState } from "react";
import { PolotnoContainer, SidePanelWrap, WorkspaceWrap } from "polotno";
import { Toolbar } from "polotno/toolbar/toolbar";
import { ZoomButtons } from "polotno/toolbar/zoom-buttons";
import { SidePanel, DEFAULT_SECTIONS } from "polotno/side-panel";
import { Workspace } from "polotno/canvas/workspace";

import "@blueprintjs/icons/lib/css/blueprint-icons.css";
import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/popover2/lib/css/blueprint-popover2.css";

import { createStore } from "polotno/model/store";
import { Container, Row, Col, Button } from "reactstrap";
import { DownloadButton } from "polotno/toolbar/download-button";
import { observer } from "mobx-react-lite";
import FaShapes from "@meronex/icons/fa/FaShapes";
import { SectionTab } from "polotno/side-panel";
import axios from "axios";
import {useLocation, useNavigate} from "react-router-dom";
import {toast, ToastContainer } from 'react-toastify';

// let certId;
let agentID;
const store = createStore({
    key: "nFA5H9elEytDyPyvKL7T",
    showCredit: true,
});
store.addPage();
store.setSize(2480,3508,true)

const ActionControls = ({ store, onExportSVG }) => {
    return (
        <div>
            {/*/!* <DownloadButton store={store} /> *!/---------------------------------*/}
            <Button color="primary" onClick={onExportSVG} className="ml-2">
                Save Template
            </Button>
        </div>
    );
};

const CustomToolbar = ({ store, onExportSVG }) => {
    return (
        <Toolbar
            store={store}
            components={{
                ActionControls: (props) => <ActionControls {...props} onExportSVG={onExportSVG} />,
            }}
        />
    );
};

const CustomSection = {
    name: "custom",
    Tab: (props) => (
        <SectionTab name="Custom" {...props}>
            <FaShapes icon="new-text-box" />
        </SectionTab>
    ),
    Panel: observer(({ store }) => {
        const addTextElement = (text) => {
            store.activePage.addElement({
                type: "text",
                text: text,
                x: store.width / 2,
                y: store.height / 2,
                
            });
        };
        const [fields, setfields] = useState(null);
        const fetchFields = async ()=>{
            // console.log("www", certId);
            const certId = localStorage.getItem('certId');
            console.log("certId", certId);
            try{
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/auth/list-by-params/field/${agentID}`,{
                certId:certId,
                skip:0,
                per_page:100,
                sorton:"fieldsName",
                sortdir:"asc",
                match:"",
                isActive:true},)
                
                setfields(response.data);
            }
            catch(error){
                console.log(error);
            }
                
        };

        useEffect(() => {
            fetchFields();
        },[])
        return (
            <div>
                <h3>Custom Elements</h3>

            <div style={{display: "flex", flexWrap: "wrap"}}>
                {
                    fields && fields.map((field) => {
                        return (
                            <Button key={field.key} style={{margin: "8px"}} onClick={() => addTextElement(field.key)}>
                                {field.key}
                            </Button>
                        );
                    })
                }
                {/* <Button style={{margin: "8px"}} onClick={() => addTextElement("$companyName")}>
                    $companyName
                </Button>
                <Button style={{margin: "8px"}} onClick={() => addTextElement("$issueDate")}>
                    $issueDate
                </Button>
                <Button style={{margin: "8px"}} onClick={() => addTextElement("$expiryDate")}>
                    $expiryDate
                </Button> */}
            </div>
            </div>
        );
    }),
};

const sections = [CustomSection, ...DEFAULT_SECTIONS];

const CustomSidePanel = () => {
    return <SidePanel store={store} sections={sections} defaultSection="custom" />;
};

const PolotnoCanvas = ({props}) => {
    document.title = "Design Certificate | EHS";

    const location = useLocation();
    const [certificateId, setCertificateId] = useState(location.state.certificateId);
    const [certificateTemplateData, setCertificateTemplateData] = useState(null);
    const [certificateTemplateId, setCertificateTemplateId] = useState('');

    useEffect(() => {
        if(location.state.certificateTemplate===null) {
            // certId=location.state.certificateId;
            setCertificateTemplateId('');
            setCertificateTemplateData(null);
            store.clear();
            store.addPage();
            // localStorage.setItem('certId', location.state.certificateId);
        }
        else {
            setCertificateTemplateId(location.state.certificateTemplate.templateID);
            setCertificateTemplateData(location.state.certificateTemplate.file);
            store.loadJSON(location.state.certificateTemplate.file);
        }
    },[location.state]);
    // useEffect(() => {
    //     if(certificateTemplateData!==null)
    //     store.loadJSON(certificateTemplateData);
    // }, []);
    const [stage, setStage] = useState(null);

    const navigate = useNavigate();

    const agentId = localStorage.getItem('agentId');
    agentID = localStorage.getItem('agentId');

    useEffect(() => {
        console.log("Stage:", stage);
    }, [stage]);


    const handleExportSVG = async () => {
        let template = store.toJSON();
        // console.log();
        if(!certificateTemplateId) {
            try {
                const resp = await axios.post(`${process.env.REACT_APP_BASE_URL}/auth/create/certificateTemplate`,
                    {
                        file: template,
                        isActive: true,
                        agent: agentId,
                        certificate: certificateId
                    }
                )
                if (resp.certificateTemplate) {
                    navigate('/my-services');
                    toast.success("Template created successfully");
                    localStorage.removeItem('certId');
                } else {
                    toast.error("Error creating template");
                }
            } catch (error) {
                toast.error("Template Already Exists");
            }
        }
        else {
            try {
                const resp = await axios.put(`${process.env.REACT_APP_BASE_URL}/auth/update/template/${certificateTemplateId}`,
                    {
                        file: template,
                    }
                )
                if (resp.file) {
                    navigate('/my-services');
                    toast.success("Template updated successfully");
                } else {
                    toast.error("Error updating template");
                }
            } catch (error) {
                toast.error("Error updating template");
            }
        }
    };
    return (
        <div className="page-content">
            <Container fluid>
                <Row>
                    <Col xs={12}>
                        <PolotnoContainer style={{ width: "100%", height: "100vh" }}>
                            <SidePanelWrap>
                                <CustomSidePanel />
                            </SidePanelWrap>
                            <WorkspaceWrap>
                                <CustomToolbar certificateTemplateId={certificateTemplateId} store={store} onExportSVG={handleExportSVG} />
                                <Workspace store={store} onReady={(stage) => setStage(stage)} />
                                <ZoomButtons store={store} />
                            </WorkspaceWrap>
                        </PolotnoContainer>
                    </Col>
                </Row>
            </Container>
            <ToastContainer />
        </div>
    );
};

export default PolotnoCanvas;


