import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button, Box, Grid, Typography, MenuItem, TextField } from '@mui/material';
import SeearchableDropdown from './SeearchableDropdown'; // Ensure correct import path
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; // Import toastify CSS for styles

const AddField = ({ isOpen, toggle, onFieldAdded }) => {
    const [selectedCertificates, setSelectedCertificates] = useState([]);
    const [selectedCertificate, setSelectedCertificate] = useState(null); // Define selectedCertificate state
    const [agentId, setAgentId] = useState('');
    const [fieldType, setFieldType] = useState('text');
    const [fieldName, setFieldName] = useState('');
    const [errors, setErrors] = useState({}); // Error state for validation

    useEffect(() => {
        const agentId = localStorage.getItem('agentId');
        if (agentId) {
            setAgentId(agentId);
        }
    }, []);

    const validate = () => {
        const newErrors = {};
        if (selectedCertificates.length === 0) {
            newErrors.selectedCertificates = 'Please select at least one certificate.';
        }
        if (!fieldName.trim()) {
            newErrors.fieldName = 'Field name is required.';
        }
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleFieldTypeChange = (event) => {
        setFieldType(event.target.value);
    };

    const handleFieldNameChange = (event) => {
        setFieldName(event.target.value);
    };

    const handleSubmit = async () => {
        if (!validate()) {
            return;
        }
        try {
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/auth/create/field`, {
                certificate: selectedCertificates,
                fieldName,
                fieldType,
                agent: agentId,
                isActive: true
            });
            console.log('Response:', response.data);
            // Update fieldsData in parent component after successful submission
            onFieldAdded(); // This should trigger a refresh of fieldsData in FieldsTable
            // Close the modal
            toggle(); // Ensure toggle function closes the modal in FieldsTable
            toast.success("Field added successfully");
        } catch (error) {
            console.error('Error creating field:', error);
            toast.error("Error creating field");
        }
    };

    return (
        <Box display="flex" flexDirection="column" alignItems="center" mt={4} height={'28rem'}>
            <Typography variant="h4" component="div" style={{ textAlign: 'center', fontWeight: 'bold' }}>
                Add Fields
            </Typography>
            <Grid container spacing={2} mt={3} style={{ maxWidth: '600px' }}>
                <Grid item xs={12}>
                    <SeearchableDropdown
                        selectedCertificates={selectedCertificates}
                        setSelectedCertificates={setSelectedCertificates}
                        setSelectedCertificate={setSelectedCertificate}
                    />
                    {errors.selectedCertificates && (
                        <Typography color="error" variant="body2">
                            {errors.selectedCertificates}
                        </Typography>
                    )}
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        select
                        label={<><span>Field Type</span><span style={{ color: 'red' }}>*</span></>}
                        variant="outlined"
                        value={fieldType}
                        onChange={handleFieldTypeChange}
                        error={!!errors.fieldType}
                        helperText={errors.fieldType}
                    >
                        <MenuItem value="text">Text</MenuItem>
                        <MenuItem value="number">Number</MenuItem>
                        <MenuItem value="date">Date</MenuItem>
                    </TextField>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label={<><span>Field Name</span><span style={{ color: 'red' }}>*</span></>}
                        variant="outlined"
                        value={fieldName}
                        onChange={handleFieldNameChange}
                        error={!!errors.fieldName}
                        helperText={errors.fieldName}
                    />
                </Grid>
                <Grid item xs={12} style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={toggle} // Ensure toggle function works correctly
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSubmit}
                    >
                        Submit
                    </Button>
                </Grid>
            </Grid>
            <ToastContainer />
        </Box>
    );
};

export default AddField;
